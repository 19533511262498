import React, { useState } from 'react';
import { action as deleteMember } from './action';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DeleteIconElem } from '../../common/delete-icon';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { LoaderElem } from '../../common/loader';
import { ModalConfirmElem } from '../../common/modal-confirm';

export const Container: React.FC<{
  eventId: string;
  open: boolean;
  onClose: () => void;
}> = ({ eventId, onClose, open }) => {
  const queryClient = useQueryClient();

  const action = useMutation(() => deleteMember(eventId), {
    onSuccess: () => {
      return queryClient.invalidateQueries(EVENT_LIST_MODULE_NAME);
    },
  });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const onClick = () => {
    action.mutate();
  };

  return (
    <div style={{ display: 'none' }}>
      {isLoading() && <LoaderElem />}

      <ModalConfirmElem
        action={onClick}
        open={open}
        onClose={onClose}
        type="delete"
      ></ModalConfirmElem>
    </div>
  );
};
