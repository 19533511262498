import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const getPersonList = (sessionId: string, term?: string) => {
  console.log(term);
  return HttpRequest({
    method: API.LIST.TYPE,
    url: API.LIST.URL(sessionId, term),
  });
};
