import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PERSON_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const SORT_OPTION_LIST = [
  { value: 'name_desc', label: i18n.t('PERSON.FILTER.SORT.NAME_DESC') },
  { value: 'name_asc', label: i18n.t('PERSON.FILTER.SORT.NAME_ASC') },
];
