import React from 'react';
import { Component } from './component';
import { useQuery } from 'react-query';
import { SESSION_ITEM_DATA_INTER } from '../../data/session/constant';
import { SIZE_LAYOUT_TYPE } from '../../theme/size';
import { SESSION_DATA_MODULE_NAME } from '../session-data';

export const Container: React.FC<{
  size?: SIZE_LAYOUT_TYPE;
  sessionId: string;
}> = ({ size, sessionId }) => {
  const preFetch = useQuery([SESSION_DATA_MODULE_NAME, sessionId]);

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const getData = (): SESSION_ITEM_DATA_INTER | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA_INTER;
    if (data) {
      return data;
    }
  };

  const data = getData();

  return (
    <Component
      data={data}
      size={size}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      sessionId={sessionId}
    />
  );
};
