import React from 'react';
import { QUESTION_TYPE } from '../../../data/event/constant';
import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { Spacing } from '../../../theme';
import { FlexElem } from '../../../common/flex';
import { ReactComponent as QuestionIcon } from '../../../asset/svg/session/room/question.svg';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';

export const QuestionEvent: React.FC<{
  data: QUESTION_TYPE;
}> = ({ data }) => {
  const { name, message } = data;
  return (
    <Container>
      <Title>
        <QuestionIconStyled />
        <TextElem tid="EVENT.QUESTION.TITLE" type="semi-bold" lineHeight />
      </Title>
      <MessageContainer>
        <TextElem tid={name} />
        <TextElem tid={message} />
      </MessageContainer>
    </Container>
  );
};

const QuestionIconStyled = styled(QuestionIcon)``;

const Container = styled(GridElem)`
  gap: ${Spacing(3)};
  /* media {
    gap: ${Spacing(2)};
  } */
`;

const Title = styled(FlexElem)`
  gap: ${Spacing(2)};
  align-content: center;
  /* media {
    gap: ${Spacing(1)};
  } */
`;

const MessageContainer = styled(GridElem)`
  padding: ${Spacing(3)};
  border-radius: 12px;
  gap: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  /* media {
    gap: ${Spacing(2)};
  } */
`;
