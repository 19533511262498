import {
  EVENT_ITEM_DATA_RAW_INTER,
  EVENT_ITEM_DATA_INTER,
  EVENT_ITEM_LIST_DATA_INTER,
  EVENT_IMG_DATA,
  REACTION_TYPE,
  REACTION_RAW_TYPE,
} from './constant';

const convertReaction = (reaction: REACTION_RAW_TYPE): REACTION_TYPE[] => {
  return reaction && Object.values(reaction);
};
export const convertEvent = (
  event: EVENT_ITEM_DATA_RAW_INTER,
): EVENT_ITEM_DATA_INTER => {
  const reactionArray: REACTION_TYPE[] = convertReaction(event.reaction);
  console.log(event);
  console.log(event.reaction);
  return {
    ...event,
    reaction: reactionArray,
    TypeIcon: EVENT_IMG_DATA[event.type],
    emojiList: reactionArray
      ?.map((item) => item.emoji)
      .toString()
      .replaceAll(',', '  '),
    reactionCounter: reactionArray?.length,
  };
};

export const convertEventList = (
  personList: EVENT_ITEM_DATA_RAW_INTER[],
): EVENT_ITEM_LIST_DATA_INTER => {
  return {
    list: personList?.map((person: EVENT_ITEM_DATA_RAW_INTER) => {
      return convertEvent(person);
    }),
    isEmpty: !personList?.length,
  };
};
