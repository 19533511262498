import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, CALENDAR, MODULE_NAME } from './constant';
import { Component } from './component';
import { convertViewerList } from '../../data/viewer/convert';
import { VIEWER_ITEM_DATA_RAW_INTER } from '../../data/viewer/constant';
import { action, getOnlineAction } from './action';

export const Container: React.FC<{
  sessionId: string;
}> = ({ sessionId }) => {
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [online, setOnline] = useState(false);
  const [date, setDate] = useState(false);
  const [range, setRange] = useState<string | null>(null);
  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME, sessionId],
    queryFn: ({ pageParam = 1 }) =>
      action({
        pageParam,
        category: value,
        sessionId,
        search,
        date,
        range,
      }),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : 1;
    },
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const getOnline = useQuery(
    ['getOnlineViewer', MODULE_NAME, sessionId],
    () => getOnlineAction(sessionId),
    {
      enabled: online,
      refetchInterval: 5000,
    },
  );

  const data = useMemo(() => {
    const allPages = preFetch?.data?.pages.reduce((acc: any, page: any) => {
      return [...acc, ...page];
    }, []) as VIEWER_ITEM_DATA_RAW_INTER[];

    if (online && getOnline.isSuccess && Array.isArray(getOnline.data)) {
      const getData = (): string[] | undefined => {
        const data = getOnline.data as unknown as string[];

        if (data) {
          return data;
        }
      };

      return convertViewerList(
        allPages.filter(
          (item: VIEWER_ITEM_DATA_RAW_INTER) => getData()?.includes(item.id), // Используем getOnline.data.data
        ),
      );
    } else {
      return convertViewerList(allPages);
    }
  }, [preFetch?.data, online, getOnline.isSuccess, getOnline.data]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  useEffect(() => {
    preFetch.refetch();
  }, [range, value, search, date]);

  return (
    <Component
      value={value}
      setValue={setValue}
      search={search}
      setSearch={setSearch}
      setRange={setRange}
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      sessionId={sessionId}
      online={online}
      setOnline={setOnline}
      setDate={setDate}
      date={date}
      range={range}
      hasNextPage={preFetch.hasNextPage}
    />
  );
};
