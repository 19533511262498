import { FILE_ITEM_DATA_RAW_INTER } from '../file/constant';
import { SESSION_ITEM_DATA_RAW_INTER } from '../session/constant';
import { WEBINAR_ITEM_DATA_RAW_INTER } from '../webinar/constant';
import { i18n } from '../../lib/lang';

export enum BAN_TYPE {
  NONE = 'NONE',
  PERMANENT = 'PERMANENT',
}

export enum PERSON_ROLE {
  USER = 'USER',
  MODER = 'MODER',
}

export interface PERSON_ITEM_DATA_RAW_INTER {
  id: string;
  name: string;
  phone: string;
  photo: FILE_ITEM_DATA_RAW_INTER;
  webinar: WEBINAR_ITEM_DATA_RAW_INTER;
  session: SESSION_ITEM_DATA_RAW_INTER;
  city: string;
  country: string;
  role: PERSON_ROLE;
}

export const PERSON_ROLE_OPTION_LIST = [
  {
    value: PERSON_ROLE.USER,
    label: `${i18n.t('USER.ROLE.USER')}`,
  },
  {
    value: PERSON_ROLE.MODER,
    label: `${i18n.t('USER.ROLE.MODER')}`,
  },
];

export interface PERSON_ITEM_LIST_DATA_RAW_INTER {
  list: PERSON_ITEM_DATA_RAW_INTER[];
}

export interface PERSON_ITEM_DATA_INTER extends PERSON_ITEM_DATA_RAW_INTER {
  status?: string;
}

export interface PERSON_ITEM_LIST_DATA_INTER {
  list: PERSON_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: (sessionId: string, term?: string) =>
      `person/list/${sessionId}?search=${term}`,
  },
};
