import { WEBINAR_UPDATE_FORM_VALUE_ENUM } from '../webinar-item-update-general';
import { FORM_VALUE_INTER } from '../webinar-item-update-general/constant';

export const convert = (data: FORM_VALUE_INTER) => ({
  ...data,
  [WEBINAR_UPDATE_FORM_VALUE_ENUM.YOUTUBE_LINK]:
    data[WEBINAR_UPDATE_FORM_VALUE_ENUM.YOUTUBE_LINK]?.trim(),
  [WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_EVENT_NAME]:
    data[WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_EVENT_NAME]?.trim(),
  [WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_FORM_NAME]:
    data[WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_FORM_NAME]?.trim(),
  [WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_LINK]:
    data[WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_LINK]?.trim(),
  [WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_PIPELINE]:
    data[WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_PIPELINE]?.trim(),
  [WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_STATUS]:
    data[WEBINAR_UPDATE_FORM_VALUE_ENUM.CRM_STATUS]?.trim(),
});
