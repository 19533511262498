import {
  WEBINAR_ITEM_LIST_DATA_RAW_INTER,
  WEBINAR_ITEM_LIST_DATA_INTER,
} from '../../data/webinar/constant';

export const MODULE_NAME = 'WEBINAR_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `/webinar/list/${params}`,
};

export interface ACTION_RESPONSE_INTER
  extends WEBINAR_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends WEBINAR_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
