import { i18n } from '../../lib/lang';
import {
  VIEWER_ITEM_DATA_RAW_INTER,
  VIEWER_ITEM_DATA_INTER,
  VIEWER_ITEM_LIST_DATA_RAW_INTER,
  VIEWER_ITEM_LIST_DATA_INTER,
  UTMObject,
} from './constant';

export const filterUTM = (obj?: UTMObject): Partial<UTMObject> => {
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'googleClientId',
  ];

  if (obj) {
    return Object.keys(obj)
      .filter((key) => utmKeys.includes(key))
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {} as Partial<UTMObject>);
  } else {
    return {};
  }
};

export const convertStatus = (viewer: VIEWER_ITEM_DATA_RAW_INTER): string => {
  if (viewer?.reservation?.payment?.confirm) {
    return 'STATUS_PAID';
  }

  if (viewer?.reservation) {
    return 'STATUS_SENT';
  }
  if (viewer?.isButtonOpen) {
    return 'STATUS_BUTTON_PRESSED';
  }
  if (viewer?.isButtonSee) {
    return 'STATUS_BUTTON_NOT_PRESSED';
  }
  return 'STATUS_NOT_SEEN';
};

function parseTime(timeStr: string) {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function formatTime(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return i18n.t('VIEWER.ITEM.WATCH_TIME_VALUE', {
    min: minutes,
    sec: seconds,
  });
}

export function calculateTotalViewingTime(intervals: string[][]) {
  let totalSeconds = 0;

  intervals.forEach(([start, end]) => {
    const startTime = parseTime(start);
    const endTime = parseTime(end);
    totalSeconds += Math.abs(endTime - startTime);
  });

  return formatTime(totalSeconds);
}

export const convertViewer = (
  viewer: VIEWER_ITEM_DATA_RAW_INTER,
): VIEWER_ITEM_DATA_INTER => {
  return {
    ...viewer,
    watchTime: calculateTotalViewingTime(viewer.duration),
    status: convertStatus(viewer),
  };
};

export const convertViewerList = (
  viewerList: VIEWER_ITEM_DATA_RAW_INTER[],
): VIEWER_ITEM_LIST_DATA_INTER => {
  return {
    list: viewerList?.map((viewer: VIEWER_ITEM_DATA_RAW_INTER) => {
      return convertViewer(viewer);
    }),
    isEmpty: !viewerList?.length,
  };
};

export const convertViewerToExcel = (viewer: VIEWER_ITEM_DATA_INTER): any => {
  return {
    id: viewer.id,
    name: viewer.name,
    phone: viewer.phone,
    ip: viewer.ip[0],
    location: viewer.location[0],
    needRecord: viewer.needRecord,
    reservation: viewer.reservation ? '+' : '-',
    payeed: viewer.reservation?.payment?.confirm ? '+' : '-',
    sessionName: viewer.session.name,
    webinarName: viewer.session.webinar.name,
  };
};

export const convertViewerToExcelList = (
  viewerList?: VIEWER_ITEM_DATA_INTER[],
): any => {
  return viewerList?.map((viewer: VIEWER_ITEM_DATA_RAW_INTER) => {
    return convertViewerToExcel(viewer);
  });
};
