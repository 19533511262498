import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';
import { LinkElem } from '../../common/link';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridElem spacing={4}>
        <TitleContainer spacing={3}>
          <TextElem size="heading" type="semi-bold" tid="AUTH.RECOVERY.TITLE" />
        </TitleContainer>

        <FieldText
          name={FORM_VALUE_ENUM.EMAIL}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="AUTH.RECOVERY.EMAIL"
          value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
          error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
          type="email"
        />
        <LinkElem
          tid="AUTH.RECOVERY.LINKS.LOGIN.LINK"
          prefixTid="AUTH.RECOVERY.LINKS.LOGIN.PREFIX"
          link={AUTH_LOGIN_PAGE_PATH}
        />

        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          tid="AUTH.RECOVERY.BUTTON"
        />
        {isError && <AlertActionElem text={errorMessage} />}
      </GridElem>
    </FormElem>
  );
};

const FieldText = styled(FieldTextElem)`
  input {
    :focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
    }

    :hover {
      background: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const TitleContainer = styled(GridElem)`
  justify-items: center;
`;
