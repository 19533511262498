import { PERSON_ITEM_DATA_RAW_INTER } from '../person/constant';
import { SESSION_ITEM_DATA_RAW_INTER } from '../session/constant';
import { VIEWER_ITEM_DATA_RAW_INTER } from '../viewer/constant';

export interface MESSAGE_ITEM_DATA_RAW_INTER {
  id: string;
  viewer: VIEWER_ITEM_DATA_RAW_INTER;
  person?: PERSON_ITEM_DATA_RAW_INTER;
  session: SESSION_ITEM_DATA_RAW_INTER;
  replyTo?: MESSAGE_ITEM_DATA_RAW_INTER;
  content: string;
  updateDate: string;
  createDate: string;
}

export interface REPLAY_MESSAGE_DATA {
  id: string;
  name: string;
  content: string;
}

export interface MESSAGE_ITEM_LIST_DATA_RAW_INTER {
  list: MESSAGE_ITEM_DATA_RAW_INTER[];
}

export interface MESSAGE_ITEM_DATA_INTER extends MESSAGE_ITEM_DATA_RAW_INTER {}

export interface MESSAGE_ITEM_LIST_DATA_INTER {
  list: MESSAGE_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: (sessionId: string, term?: string) =>
      `message/list/${sessionId}?search=${term}`,
  },
};
