import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { RouteComponentProps } from 'react-router';

import { WebinarItemDataContainer } from '../../epic/webinar-item-data';
import { WebinarHeaderContainer } from '../../epic/webinar-header';
import { WebinarItemUpdateTopicContainer } from '../../epic/webinar-item-update-topic';
import { WebinarItemUpdateDescriptionContainer } from '../../epic/webinar-item-update-description';
import { WebinarItemUpdateGiftContainer } from '../../epic/webinar-item-update-gift';
import { WebinarItemUpdateButtonLinkContainer } from '../../epic/webinar-item-update-button-link';
import { WebinarItemUpdateActionContainer } from '../../epic/webinar-item-update-action';
import { WebinarItemUpdateModerMessageContainer } from '../../epic/webinar-item-update-moder-message';
interface WebinarSettingsPageProps
  extends RouteComponentProps<{
    webinarId: string;
  }> {}

export const Page: React.FC<WebinarSettingsPageProps> = ({ match }) => {
  const webinarId = match.params.webinarId;

  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />
        <LayoutElem>
          <WebinarItemDataContainer webinarId={webinarId} />
          <WebinarHeaderContainer webinarId={webinarId} />
          <LayoutSectionElem spacing={5}>
            <WebinarItemUpdateGiftContainer webinarId={webinarId} />
            <WebinarItemUpdateDescriptionContainer webinarId={webinarId} />
            <WebinarItemUpdateTopicContainer webinarId={webinarId} />
            <WebinarItemUpdateButtonLinkContainer webinarId={webinarId} />
            <WebinarItemUpdateModerMessageContainer webinarId={webinarId} />
            <WebinarItemUpdateActionContainer webinarId={webinarId} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
