import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { AlertActionElem } from '../../common/alert-action';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';

import { FileItemUploadContainer } from '../file-item-upload';
import { DoubleContainerElem } from '../../common/double-container';
import { FlexElem } from '../../common/flex';
import { WEBGIFT_ITEM_DATA_RAW_INTER } from '../../data/webgift/constant';
import { LoaderElem } from '../../common/loader';
import { WebgiftItemDeleteContainer } from '../webgift-item-delete';
import { FORM_VALUE_ENUM } from '../webgift-item-create/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data: WEBGIFT_ITEM_DATA_RAW_INTER;
  onSuccessUpload: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  onSuccessUpload,
}) => {
  return (
    <Container spacing={4}>
      {isLoading && <LoaderElem />}
      <DoubleContainerElem>
        <GridElem spacing={4}>
          <FlexElem style={{ justifyContent: 'space-between', gap: '8px' }}>
            <FieldTextElem
              name={FORM_VALUE_ENUM.NAME}
              onChange={formik.handleChange}
              onBlur={formik.handleSubmit}
              title="WEBINAR.UPDATE.GIFT.FORM.NAME"
              value={getFieldValue(FORM_VALUE_ENUM.NAME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
              error={isFieldError(FORM_VALUE_ENUM.NAME)}
              style={{ width: '100%' }}
            />
            <WebgiftItemDeleteContainer webgift={data.id} />
          </FlexElem>

          <FieldTextElem
            name={FORM_VALUE_ENUM.DESCRIPTION}
            onChange={formik.handleChange}
            onBlur={formik.handleSubmit}
            title="WEBINAR.UPDATE.GIFT.FORM.DESCRIPTION"
            value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
          />
        </GridElem>
        <GridElem spacing={3}>
          <TextElem
            tid="WEBINAR.UPDATE.GIFT.FORM.PHOTO"
            color="textPrimary"
            style={{ height: '14px' }}
          />
          <FileItemUploadContainer
            onSuccess={onSuccessUpload}
            name={FORM_VALUE_ENUM.FILE}
            type="image"
            // description={false}
            defaultValue={data.photo?.url}
          />
        </GridElem>
      </DoubleContainerElem>

      {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
    </Container>
  );
};

const Container = styled(GridElem)`
  .file-upload-wrapper {
    height: 134px !important;
    min-height: 134px !important;
  }
`;
