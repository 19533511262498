import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { convertFileList } from '../../data/file/convert';
import { action } from './action';
import { FILE_ITEM_DATA_RAW_INTER } from '../../data/file/constant';

export const Container: React.FC<{}> = () => {
  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME],
    queryFn: ({ pageParam = 1 }) => action(pageParam),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : undefined;
    },
  });

  const data = useMemo(() => {
    return convertFileList(
      preFetch?.data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page];
      }, []) as FILE_ITEM_DATA_RAW_INTER[],
    );
  }, [preFetch?.data]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      hasNextPage={preFetch.hasNextPage}
    />
  );
};
