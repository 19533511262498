import styled from 'styled-components';
import { FlexElem } from '../../../common/flex';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { ReactComponent as ViewerIcon } from '../../../asset/svg/session/viewer-light.svg';

export const ViewerCounter: React.FC<{
  className?: string;
  placeReserved?: number;
  placeAvailable?: number;
}> = ({ className, placeAvailable = 100, placeReserved = 60 }) => {
  return (
    <Container spacing={1} className={className}>
      <p>
        <TextElem
          tid="SESSION.VIEWER.RESERVATION.CHIP"
          type="semi-bold"
          color="textThird"
          size="medium"
        />
      </p>

      <ViewerIcon />
      <p>
        <TextElem
          tid={`${placeReserved}`}
          size="medium"
          type="semi-bold"
          color="textSecondary"
        />
        <TextElem
          tid={`/${placeAvailable}`}
          size="medium"
          type="semi-bold"
          color="textThird"
        />

        <TextElem
          tid="SESSION.VIEWER.RESERVATION.PLACE"
          type="semi-bold"
          color="textFourth"
          size="medium"
        />
      </p>
    </Container>
  );
};

const Container = styled(FlexElem)`
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
  border-radius: 12px;
  width: auto;
  padding: ${Spacing(0)} ${Spacing(5)};
  height: 58px;
  align-items: center;
  justify-content: center;
  svg {
    margin-bottom: 4px;
  }
  @media screen and (width<700px) {
    height: 35px;

    svg {
      height: 10px;
      width: 10px;
      margin-bottom: 1px;
    }
    span {
      font-size: 11px;
    }
  }
  p > span {
    line-height: 1em;
  }
  p {
    line-height: 1em;
  }
`;
