import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { RouteComponentProps, useLocation } from 'react-router';

import { SessionItemOverContainer } from '../../epic/session-item-over';
import queryString from 'query-string';

interface SessionItemPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionItemPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;

  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <PageElem>
      <LayoutAppElem>
        <LayoutElem oneColumn size="small" style={{ paddingTop: '55px' }}>
          <SessionItemOverContainer sessionId={sessionId} query={query} />
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
