import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  EXPENDITURE_BILL_COLOR,
  EXPENDITURE_ITEM_DATA_INTER,
  EXPENDITURE_STATUS_COLOR,
} from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';
import { ExpenditureItemUpdateContainer } from '../../../epic/expenditure-item-update';
import { ContentContainerElem } from '../../../common/content-container';
import { TextElem } from '../../../common/text';
import { GridElem } from '../../../common/grid';
import { FlexElem } from '../../../common/flex';
import { CURRENCY_TYPE_SYMBOL } from '../../valute/constant';
import { convertDateWithYear } from '../../../lib/lang/service';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

const Elem: React.FC<{
  data: EXPENDITURE_ITEM_DATA_INTER;
  updateVisible: string | null;
  setUpdateVisible: Function;
}> = ({ data, setUpdateVisible, updateVisible }) => {
  const {
    id,
    createDate,
    amount,
    valute,
    bill,
    category,
    departmant,
    responsible,
    status,
    deadLine,
    comment,
  } = data;

  const ref = useRef(null);

  const handleUpdateVisible = (e: any) => {
    setUpdateVisible(data.id);
  };

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
  };

  const handleCloseModal = (e: any) => {
    if (e) {
      e.stopPropagation();
    }

    setUpdateVisible(null);
  };

  const [height, setHeight] = useState(0);
  const updRef = useRef<any>(null);

  useEffect(() => {
    if (updRef.current) {
      setHeight(updRef.current?.offsetHeight);
    }
  }, [updateVisible]);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {width < 1100 ? (
        <Card
          height={height}
          updVisible={updateVisible === data.id}
          handleClick={handleUpdateVisible}
        >
          {updateVisible === data.id && (
            <Div ref={updRef}>
              <ContentContainerElem>
                <ExpenditureItemUpdateContainer
                  data={data}
                  close={handleCloseModal}
                />
              </ContentContainerElem>
            </Div>
          )}
          <MobileField spacing={4}>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.DATE"
            />

            <TextElem type="medium" color="textPrimary" oneLine>
              {convertDateWithYear(createDate)}
            </TextElem>
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.DEADLINE"
            />
            <TextElem type="medium" color="textFourth" oneLine>
              {convertDateWithYear(deadLine)}
            </TextElem>
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.AMOUNT"
            />
            <TextElem type="medium" color="textFourth" oneLine>
              {`${CURRENCY_TYPE_SYMBOL[valute]} ${amount}`}
            </TextElem>
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.BILL"
            />
            <TextElem
              tid={`EXPENDITURE.BILL.${bill}`}
              type="medium"
              color={EXPENDITURE_BILL_COLOR[bill]}
              oneLine
            />
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.DEPARTMENT"
            />
            <TextElem
              tid={`EXPENDITURE.DEPARTMENT.${departmant}`}
              type="regular"
              color="textFourth"
              oneLine
            />
          </MobileField>

          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.CATEGORY"
            />
            <TextElem
              tid={`EXPENDITURE.CATEGORY.${category}`}
              type="regular"
              color="textFourth"
              oneLine
            />
          </MobileField>

          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.RESPONSIBLE"
            />
            <TextElem type="medium" color="textFourth" oneLine>
              {`${responsible.name}`}
            </TextElem>
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.STATUS"
            />
            <TextElem
              tid={`EXPENDITURE.STATUS.${status}`}
              type="medium"
              color={EXPENDITURE_STATUS_COLOR[status]}
              oneLine
            />
          </MobileField>
          <MobileField style={{ gridColumn: '1/5' }}>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.COMMENT"
            />
            <EllipsisContainerElem>
              <TextElem type="medium" color="textFourth" oneLine>
                {comment || '—'}
              </TextElem>
            </EllipsisContainerElem>
          </MobileField>
        </Card>
      ) : (
        <Card
          height={height}
          updVisible={updateVisible === data.id}
          handleClick={handleUpdateVisible}
        >
          {updateVisible === data.id && (
            <Div ref={updRef}>
              <ContentContainerElem>
                <ExpenditureItemUpdateContainer
                  data={data}
                  close={handleCloseModal}
                />
              </ContentContainerElem>
            </Div>
          )}

          <Flex>
            <TextElem type="medium" color="textPrimary" oneLine>
              {convertDateWithYear(createDate)}
            </TextElem>
          </Flex>

          <Flex>
            <TextElem type="medium" color="textFourth" oneLine>
              {convertDateWithYear(deadLine)}
            </TextElem>
          </Flex>
          <Flex>
            <TextElem type="semi-bold" color="textSecondary" oneLine>
              {`${CURRENCY_TYPE_SYMBOL[valute]} ${amount}`}
            </TextElem>
          </Flex>

          <Flex>
            <TextElem
              tid={`EXPENDITURE.BILL.${bill}`}
              type="medium"
              color={EXPENDITURE_BILL_COLOR[bill]}
              oneLine
            />
          </Flex>

          <Flex>
            <TextElem
              tid={`EXPENDITURE.CATEGORY.${category}`}
              type="regular"
              color="textFourth"
              oneLine
            />
          </Flex>

          <Flex>
            <TextElem
              tid={`EXPENDITURE.DEPARTMENT.${departmant}`}
              type="regular"
              color="textFourth"
              oneLine
            />
          </Flex>

          <Flex spacing={2}>
            <TextElem type="medium" color="textFourth">
              {`${responsible.name}`}
            </TextElem>
          </Flex>
          <EllipsisContainerElem style={{ maxWidth: '150px' }}>
            <TextElem type="regular" color="textFourth" oneLine>
              {comment}
            </TextElem>
          </EllipsisContainerElem>
          <TextElem
            tid={`EXPENDITURE.STATUS.${status}`}
            type="medium"
            color={EXPENDITURE_STATUS_COLOR[status]}
            oneLine
          />
        </Card>
      )}
    </>
  );
};
export const MobileField = styled(GridElem)`
  gap: ${Spacing(2.5)};
  @media screen and (width<700px) {
    display: flex;
  }
`;

const Card = styled(CardElem)<{ height?: number; updVisible: boolean }>`
  display: grid;
  cursor: auto;
  align-items: center;
  grid-column: span 9;
  grid-template-columns: subgrid;
  gap: ${Spacing(3)};
  justify-content: space-between;
  position: relative;

  ${({ height, updVisible }) => {
    if (updVisible) {
      return css`
        :hover {
          border: solid 1px transparent;
          background-color: transparent;
        }
        background-color: transparent;
        height: ${height}px;
      `;
    }
  }}
  @media screen and (width<1100px) {
    gap: ${Spacing(5)};
    grid-column: span 4;
  }
  @media screen and (width<700px) {
    gap: ${Spacing(4)};
    grid-column: span 2;
  }

  @media screen and (width<580px) {
    gap: ${Spacing(4)};
    grid-column: span 1;
  }
`;

const Div = styled.div`
  width: calc(100% + 2px);
  position: absolute;
  z-index: 9;
  top: -1px;
  left: -1px;
`;

const Flex = styled(FlexElem)`
  width: auto;
  align-items: center;
`;

export { Elem as ExpenditureItemElem };
