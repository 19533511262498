import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA_INTER } from './constant';
import { SessionItemElem } from '../../data/session/frame/session-item-list.elem ';
import { SESSION_ITEM_DATA_INTER } from '../../data/session/constant';
import { SessionItemCreateContainer } from '../session-item-create';
import { Spacing } from '../../theme';
import { SessionListSkeleton } from '../../data/session/frame/skeleton';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  webinarId: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage, webinarId }) => {
  const [updateVisible, setUpdateVisible] = useState(null);
  return (
    <GridElem spacing={3}>
      <SessionItemCreateContainer webinarId={webinarId} />

      {isLoading && <SessionListSkeleton />}

      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && data && !data.isEmpty && (
        <Container style={{ width: '100%' }}>
          {data.list.map((item: SESSION_ITEM_DATA_INTER) => (
            <SessionItemElem
              data={item}
              updateVisible={updateVisible}
              setUpdateVisible={setUpdateVisible}
              key={item.id}
            />
          ))}
        </Container>
      )}
    </GridElem>
  );
};

const Container = styled.div`
  display: grid;
  @media screen and (width>=900px) {
    grid-template-columns: auto repeat(6, min-content);
    row-gap: ${Spacing(4)};
  }
  row-gap: ${Spacing(3)};
`;
