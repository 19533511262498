import React from 'react';

import { MODULE_NAME } from './constant';

import { useQuery } from 'react-query';
import { getSession } from './action';
import { convertSession } from '../../data/session/convert';

export const Container: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const preFetch = useQuery([MODULE_NAME, sessionId], () =>
    getSession(sessionId).then((data: any) => convertSession(data)),
  );
  return <></>;
};
