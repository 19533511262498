import React, { useEffect } from 'react';

import { MODULE_NAME } from './constant';

import { useQuery, useQueryClient } from 'react-query';
import { getWebinar } from './action';
import { convertWebinar } from '../../data/webinar/convert';

export const Container: React.FC<{ webinarId: string }> = ({ webinarId }) => {
  const preFetch = useQuery(
    [MODULE_NAME, webinarId],
    () => getWebinar(webinarId).then((data: any) => convertWebinar(data)),
    {
      retry: 0,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
  const queryClient = useQueryClient();


  return <></>;
};
