import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => {
  return {
    ...data,
    [FORM_VALUE_ENUM.NAME]: data[FORM_VALUE_ENUM.NAME].trim(),
    [FORM_VALUE_ENUM.COUNTRY]: data[FORM_VALUE_ENUM.COUNTRY].trim(),
    [FORM_VALUE_ENUM.CITY]: data[FORM_VALUE_ENUM.CITY].trim(),
  };
};
