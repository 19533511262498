import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = (viewerId:string) => {

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(viewerId),
  });
};
