import React from 'react';
import { EVENT_ITEM_DATA_INTER } from '../constant';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const MessageMobile: React.FC<{
  data: EVENT_ITEM_DATA_INTER;
}> = ({ data }) => {
  const { message, person } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textFourth" size="main" oneLine>
          {message}
        </TextElem>
      </EllipsisContainerElem>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textPrimary" size="main" oneLine>
          {person.name}
        </TextElem>
      </EllipsisContainerElem>
    </>
  );
};
