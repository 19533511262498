import React from 'react';
import { RESERVATION_CREATED_TYPE } from '../../../data/event/constant';
import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';

export const ReservationCreatedEvent: React.FC<{
  data: RESERVATION_CREATED_TYPE;
}> = ({ data }) => {
  const { photo, name, city, country } = data;
  return (
    <Container>
      <Avatar src={photo?.url} />
      <p>
        <TextElem type="semi-bold" size="title">
          {name}
        </TextElem>

        <TextElem
          color="textFourth"
          type="medium"
          size="title"
          tid="EVENT.RESERVATION_CREATED.TITLE"
          tvalue={{ city, country }}
        />
      </p>
    </Container>
  );
};

const Container = styled(GridElem)`
  grid-template-columns: 46px auto;
  align-items: center;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const Avatar = styled.img`
  min-width: 46px;
  min-height: 46px;
  height: 46px;
  border-radius: 100%;
`;
