import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import { Spacing } from '../../theme';

import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { EVENT_TYPE } from '../../data/event/constant';
import { FORM_VALUE_ENUM } from '../event-item-create/constant';
import { BannerForm } from '../event-item-create/frame/banner';
import { ReservationForm } from '../event-item-create/frame/reservation';
import { MessageForm } from '../event-item-create/frame/message';
import { ButtonForm } from '../event-item-create/frame/button';
import { TimecodeForm } from '../event-item-create/frame/timecode';
import { GroupMessageForm } from '../event-item-create/frame/group-message';
import { ReactionForm } from '../event-item-create/frame/reaction';
import { QuestionForm } from '../event-item-create/frame/question';
import { ReservationCreatedForm } from '../event-item-create/frame/reservationCreated';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  sessionId: string;
  file: string;
  setFieldValue: Function;
  onSuccessUpload: Function;
  getSelectValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  close,
  setFieldValue,
  sessionId,
  file,
  onSuccessUpload,
  getSelectValue,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <Container spacing={6}>
        <TitleContainer>
          <TextElem tid="EVENT.UPDATE.TITLE" type="semi-bold" size="main" />
          <CloseIcon
            onClick={() => close(false)}
            style={{ cursor: 'pointer' }}
          />
        </TitleContainer>
        {/* <TabActionElem
          tabList={EVENT_TYPE_TAB}
          name={FORM_VALUE_ENUM.TYPE}
          onChange={formik.handleChange}
          value={getFieldValue(FORM_VALUE_ENUM.TYPE)}
        /> */}

        <GridElem spacing={5}>
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.MESSAGE && (
            <MessageForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              sessionId={sessionId}
              setFieldValue={setFieldValue}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.RESERVATION && (
            <ReservationForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.GROUP_MESSAGE && (
            <GroupMessageForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              sessionId={sessionId}
              update={true}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.REACTION && (
            <ReactionForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              setFieldValue={setFieldValue}
              getSelectValue={getSelectValue}
              isSubmitDisabled={isSubmitDisabled}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.BANNER && (
            <BannerForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              onSuccessUpload={onSuccessUpload}
              file={file}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.BUTTON && (
            <ButtonForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.TIMECODE && (
            <TimecodeForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
            />
          )}

          {getFieldValue(FORM_VALUE_ENUM.TYPE) ==
            EVENT_TYPE.RESERVATION_CREATED && (
            <ReservationCreatedForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              setFieldValue={setFieldValue}
              sessionId={sessionId}
            />
          )}
          {getFieldValue(FORM_VALUE_ENUM.TYPE) == EVENT_TYPE.QUESTION && (
            <QuestionForm
              formik={formik}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              isFieldError={isFieldError}
              setFieldValue={setFieldValue}
              sessionId={sessionId}
            />
          )}
        </GridElem>
        {getFieldValue(FORM_VALUE_ENUM.TYPE) !== EVENT_TYPE.GROUP_MESSAGE && (
          <DoubleContainerElem>
            <ButtonElem
              type="submit"
              tid="EVENT.UPDATE.BUTTON.UPDATE"
              fill="solid"
              color="success"
              disabled={isSubmitDisabled()}
              onClick={formik.handleSubmit}
            />
          </DoubleContainerElem>
        )}
        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      </Container>
    </FormElem>
  );
};

const Container = styled(GridElem)`
  @media screen and (width>900px) {
    gap: ${Spacing(5)};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
