export const MODULE_NAME = 'SESION_ITEM_ROOM_EDIT_PROFILE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  PHOTO = 'photo',
  ANIMATION = 'animation',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.PHOTO]: string;
  [FORM_VALUE_ENUM.ANIMATION]: boolean | null;
}

export const API = {
  PATCH: {
    TYPE: 'PATCH',
    URL: `/viewer`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
