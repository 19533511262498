import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { FormElem } from '../../common/form';
import { ResendComponent } from './frame/resend.component';
import { LayoutSectionElem } from '../../common/layout-section';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  email: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  email,
}) => {
  return (
    <LayoutSectionElem>
      <FormElem onSubmit={formik.handleSubmit}>
        <GridElem spacing={4}>
          <TitleContainer spacing={3}>
            <TextElem
              size="heading"
              type="semi-bold"
              tid="AUTH.RECOVERY_CONFIRM.TITLE"
            />
          </TitleContainer>
          <FieldText
            name={FORM_VALUE_ENUM.CODE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="AUTH.RECOVERY_CONFIRM.CODE"
            value={getFieldValue(FORM_VALUE_ENUM.CODE)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.CODE)}
            error={isFieldError(FORM_VALUE_ENUM.CODE)}
          />
          <ResendComponent email={email} />

          <ButtonElem
            disabled={isSubmitDisabled()}
            type="submit"
            tid="AUTH.RECOVERY_CONFIRM.BUTTON"
          />
        </GridElem>
      </FormElem>
    </LayoutSectionElem>
  );
};

const FieldText = styled(FieldTextElem)`
  input {
    :focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
    }

    :hover {
      background: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const TitleContainer = styled(GridElem)`
  justify-items: center;
`;
