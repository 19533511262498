import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  PERSON_ITEM_DATA_RAW_INTER,
  PERSON_ITEM_DATA_INTER,
  PERSON_ITEM_LIST_DATA_INTER,
} from './constant';

export const convertPerson = (
  person: PERSON_ITEM_DATA_RAW_INTER,
): PERSON_ITEM_DATA_INTER => {
  return {
    ...person,
  };
};

export const convertPersonList = (
  personList: PERSON_ITEM_DATA_RAW_INTER[],
): PERSON_ITEM_LIST_DATA_INTER => {
  return {
    list: personList?.map((person: PERSON_ITEM_DATA_RAW_INTER) => {
      return convertPerson(person);
    }),
    isEmpty: !personList?.length,
  };
};

export const convertPersonListToSelect = (
  data: PERSON_ITEM_DATA_RAW_INTER[],
) => {
  const newList: SELECT_OPTION_ITEM_DATA[] = [];
  if (data) {
    data?.map((item: PERSON_ITEM_DATA_RAW_INTER) => {
      newList.push({
        value: item.id,

        label: item.name,
      });
    });
  }

  return newList;
};
