import axios from 'axios';

export const uploadAndGetRandomValue = async (fileName: string) => {
  try {
    const response = await axios.get(`/${fileName}.txt`);
    const words = response.data.split('\n').map((word: string) => word.trim());
    const array = words.flatMap((word: string) => word.split(' '));
    const randomElement = array[Math.floor(Math.random() * array.length)];
    return randomElement;
  } catch (error) {
    console.error('Error loading offensive words:', error);
    return null; // или верните какое-то значение по умолчанию
  }
};
