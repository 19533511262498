import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const startAction = (id: string) => {
  return HttpRequest({
    method: API.START.TYPE,
    url: API.START.URL(id),
  });
};

export const stopAction = (id: string) => {
  return HttpRequest({
    method: API.STOP.TYPE,
    url: API.STOP.URL(id),
  });
};
