import { IonIcon } from '@ionic/react';
import React, { useState } from 'react';

import { FieldTextElem } from '../field-text';
import { ReactComponent as EyeIcon } from '../../asset/svg/field-password/eye.svg';
import { ReactComponent as EyeIconDark } from '../../asset/svg/field-password/eye-dark.svg';
import { ReactComponent as EyeCloseIcon } from '../../asset/svg/field-password/closeEye.svg';
import { ReactComponent as EyeCloseIconDark } from '../../asset/svg/field-password/eyeOff-dark.svg';
import { PROPS_TYPE } from './constant';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { THEME_ENUM } from '../../data/theme/constant';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { COLOR_ENUM } from '../../theme/color';

export const Elem: React.FC<PROPS_TYPE> = ({ ...props }) => {
  const [visible, setVisible] = useState(false);
  const handleChange = () => {
    setVisible(!visible);
  };
  const { state } = useSelector((s: any) => ({
    state: s[THEME_MODULE_NAME],
  }));
  return (
    <FieldTextElem
      {...props}
      type={visible ? 'text' : 'password'}
      icon={
        visible ? (
          <IconContainer onClick={handleChange}>
            {state.type === THEME_ENUM.LIGHT ? <EyeIcon /> : <EyeIconDark />}
          </IconContainer>
        ) : (
          <IconContainer onClick={handleChange}>
            {state.type === THEME_ENUM.LIGHT ? (
              <EyeCloseIcon />
            ) : (
              <EyeCloseIconDark />
            )}
          </IconContainer>
        )
      }
    />
  );
};

const IconContainer = styled.div`
  height: 46px;
  display: flex;
  padding: 0 ${Spacing(4)};
  align-items: center;
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      /* fill: ${({ theme }) => theme[COLOR_ENUM.ERROR]}; */
    }
  }
`;
