import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const action = (sessionId: string, payload: FORM_VALUE_INTER) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL(sessionId),
    data: payload,
  });
};

export const getViewer = (sessionId: string) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(sessionId),
  });
};
