import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FieldPasswordElem } from '../../common/field-password';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridElem spacing={4}>
        <TitleContainer spacing={3}>
          <TextElem
            size="heading"
            type="semi-bold"
            tid="AUTH.RECOVERY_PASSWORD.TITLE"
          />
        </TitleContainer>

        <FieldPassword
          name={FORM_VALUE_ENUM.PASSWORD}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="AUTH.RECOVERY_PASSWORD.PASSWORD"
          value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
          error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
        />
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          tid="AUTH.RECOVERY_PASSWORD.BUTTON"
        />
        {isError && <AlertActionElem text={errorMessage} />}
      </GridElem>
    </FormElem>
  );
};

const FieldPassword = styled(FieldPasswordElem)`
  input {
    :focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
    }

    :hover {
      background: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const TitleContainer = styled(GridElem)`
  justify-items: center;
`;
