import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { PERSON_ITEM_DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { useOnClickOutside } from 'usehooks-ts';
import { ContentContainerElem } from '../../../common/content-container';
import { DividerElem } from '../../../common/divider';
import { FlexElem } from '../../../common/flex';
import { GridElem } from '../../../common/grid';
import { PersonItemDeleteContainer } from '../../../epic/person-item-delete';
import { PersonItemUpdateContainer } from '../../../epic/person-item-update';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { ReactComponent as EditIcon } from '../../../asset/svg/common/edit.svg';
import { ReactComponent as MoreIcon } from '../../../asset/svg/common/more.svg';
import { ReactComponent as DeleteIcon } from '../../../asset/svg/delete/delete.svg';
import { AvatarElem } from '../../../common/avatar';

const Elem: React.FC<{
  data: PERSON_ITEM_DATA_INTER;
  updateVisible: string | null;
  setUpdateVisible: Function;
}> = ({ data, setUpdateVisible, updateVisible }) => {
  const { id, name, phone, city, country, photo } = data;

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setModalVisible(false);
  };

  const closeModalVisible = () => setModalVisible(false);

  const deleteModalOpen = () => {
    setDeleteModalVisible(true);
    setTimeout(() => {
      closeModalVisible();
    });
  };

  const deleteModalClose = () => {
    setDeleteModalVisible(false);
  };

  const handleUpdateVisible = (e: any) => {
    setModalVisible(false);
    setUpdateVisible(data.id);
  };

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setUpdateVisible(null);
    setModalVisible(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const [height, setHeight] = useState(0);
  const updRef = useRef<any>(null);

  useEffect(() => {
    if (updRef.current) {
      setHeight(updRef.current?.offsetHeight);
    }
  }, [updateVisible]);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {width < 600 ? (
        <MobileContent
          height={height}
          updVisible={updateVisible === data.id}
          hover={false}
        >
          {updateVisible === data.id && (
            <Div ref={updRef}>
              <ContentContainerElem>
                <PersonItemUpdateContainer
                  data={data}
                  close={handleCloseModal}
                />
              </ContentContainerElem>
            </Div>
          )}
          <GridElem spacing={4}>
            <FlexElem
              style={{
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <FlexElem>
                <AvatarElem userAvatar={photo?.url} size={24} />
                <EllipsisContainerElem>
                  <TextElem
                    tid="SESSION.ITEM.NAME"
                    tvalue={{ value: name }}
                    type="semi-bold"
                    size="main"
                    oneLine
                  />
                </EllipsisContainerElem>
              </FlexElem>

              <ModalContainer onClick={handleOpenModal}>
                <MoreIconStyled />

                {modalVisible && (
                  <ModalElem ref={ref}>
                    <ModalItem onClick={handleUpdateVisible}>
                      <EditIcon className="editIcon" />
                      <TextElem tid="SESSION.UPDATE.MODAL.EDIT" />
                    </ModalItem>

                    <DividerElem />
                    <ModalItem onClick={deleteModalOpen}>
                      <DeleteIcon
                        style={{ filter: 'none' }}
                        className="delete"
                      />
                      <TextElem
                        tid="COMMON.MODAL.BUTTON.DELETE"
                        color="error"
                      />
                    </ModalItem>
                  </ModalElem>
                )}
              </ModalContainer>
            </FlexElem>
            <FlexElem spacing={5}>
              <GridElem spacing={3}>
                <Flex spacing={2} style={{ justifyContent: 'space-between' }}>
                  <TextElem type="medium" color="textFourth">
                    {phone}
                  </TextElem>
                </Flex>
                <TextElem type="medium" color="textPrimary" oneLine>
                  {`${country} ${city}`}
                </TextElem>
              </GridElem>
            </FlexElem>
          </GridElem>
          <PersonItemDeleteContainer
            personId={id}
            open={deleteModalVisible}
            onClose={deleteModalClose}
          />
        </MobileContent>
      ) : (
        <>
          <Card
            height={height}
            updVisible={updateVisible === data.id}
            hover={false}
          >
            {updateVisible === data.id && (
              <Div ref={updRef}>
                <ContentContainerElem>
                  <PersonItemUpdateContainer
                    data={data}
                    close={handleCloseModal}
                  />
                </ContentContainerElem>
              </Div>
            )}

            <AvatarElem userAvatar={photo?.url} size={24} />
            <EllipsisContainerElem style={{ marginLeft: '16px' }}>
              <TextElem
                tid="SESSION.ITEM.NAME"
                tvalue={{ value: name }}
                type="semi-bold"
                size="main"
                oneLine
              />
            </EllipsisContainerElem>

            <Flex>
              <TextElem
                type="medium"
                color="textFourth"
                oneLine
                style={{ paddingLeft: `${Spacing(5)}` }}
              >
                {phone}
              </TextElem>
            </Flex>

            <Flex>
              <TextElem
                type="medium"
                color="textPrimary"
                oneLine
                style={{ paddingLeft: `${Spacing(5)}` }}
              >
                {`${country} ${city}`}
              </TextElem>
            </Flex>

            <ModalContainer onClick={handleOpenModal}>
              <MoreIconStyled />

              {modalVisible && (
                <ModalElem ref={ref}>
                  <ModalItem onClick={handleUpdateVisible}>
                    <EditIcon className="editIcon" />
                    <TextElem tid="SESSION.UPDATE.MODAL.EDIT" />
                  </ModalItem>
                  <DividerElem />

                  <DividerElem />
                  <ModalItem onClick={deleteModalOpen}>
                    <DeleteIcon style={{ filter: 'none' }} className="delete" />
                    <TextElem tid="COMMON.MODAL.BUTTON.DELETE" color="error" />
                  </ModalItem>
                </ModalElem>
              )}
            </ModalContainer>
          </Card>
          <PersonItemDeleteContainer
            personId={id}
            open={deleteModalVisible}
            onClose={deleteModalClose}
          />
        </>
      )}
    </>
  );
};

const MobileContent = styled(CardElem)<{
  height?: number;
  updVisible: boolean;
}>`
  position: relative;
  cursor: auto;
  width: 100%;
  max-width: 100%;
  ${({ height, updVisible }) => {
    if (updVisible) {
      return css`
        :hover {
          border: solid 1px transparent;
          background-color: transparent;
        }
        background-color: transparent;
        height: ${height}px;
      `;
    }
  }}
  span {
    max-width: 100%;
    overflow: hidden;
    display: block;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const MoreIconStyled = styled(MoreIcon)`
  height: 24px;
  transition: all 0.2s;
  circle {
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    transition: all 0.2s;
  }
  :hover {
    circle {
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
    }
  }
`;

const Card = styled(CardElem)<{ height?: number; updVisible: boolean }>`
  display: grid;
  cursor: auto;

  grid-column: span 5;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  ${({ height, updVisible }) => {
    if (updVisible) {
      return css`
        :hover {
          border: solid 1px transparent;
          background-color: transparent;
        }
        background-color: transparent;
        height: ${height}px;
      `;
    }
  }}
`;

const Div = styled.div`
  width: calc(100% + 2px);
  position: absolute;
  z-index: 9999;
  top: -1px;
  left: -1px;
`;

const Flex = styled(FlexElem)`
  width: auto;
  align-items: end;
`;

const ModalContainer = styled.div`
  position: relative;
  height: 24px;
  display: flex;
  cursor: pointer;
  margin-left: ${Spacing(4)};
`;

const ModalItem = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  padding: ${Spacing(4)};
  align-items: center;
  height: 48px;
  border-radius: 0;
  span {
    height: 16px;
  }
  svg {
    path {
      transition: all 0.1s;
    }
  }
  :first-child {
    border-radius: 9px 9px 0 0;
  }

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};

    svg {
      circle {
        stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
    }
    .editIcon {
      path {
        fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
        stroke: transparent;
      }
    }
    svg.delete {
      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
      }
    }
  }
`;
const ModalElem = styled.div`
  z-index: 999;
  background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
  top: 30px;
  right: 0;
  width: 180px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  position: absolute;

  border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  width: 180px;
  --height: auto;
  --box-shadow: none !important;
  --backdrop-opacity: 0;

  div:first-child {
    border-radius: 9px 9px 0 0;
  }

  div:last-child {
    border-radius: 0 0 9px 9px;
  }
`;

export { Elem as PersonItemElem };
