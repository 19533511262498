import { UTMObject } from '../../data/viewer/constant';

export const MODULE_NAME = 'SESION_ITEM_JOIN_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  LANG = 'lang',
  UTM = 'utm',
  REDIRECT_LINK = 'redirect_link',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.LANG]: string;
  [FORM_VALUE_ENUM.UTM]: UTMObject;
}

export const API = {
  TYPE: 'POST',
  URL: (sessionId: string) => `/viewer/join/${sessionId}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
