//@ts-nocheck

import React, { useEffect } from 'react';

import { GridElem } from '../../common/grid';

import styled from 'styled-components';

import { SessionItemRoomScenarioContainer } from '../session-item-room-scenario';
import { SessionItemRoomHeaderContainer } from '../session-item-room-header';
import { SessionItemRoomReservationContainer } from '../session-item-room-reservation';
import { Spacing } from '../../theme';
import { SESSION_ITEM_DATA_INTER } from '../../data/session/constant';

import { FlexElem } from '../../common/flex';
import { SessionItemRoomChatContainer } from '../session-item-room-chat';
import { SessionItemRoomVideoContainer } from '../session-item-room-video';
import { ViewerItemDataContainer } from '../viewer-item-data';
import { MESSAGE_ITEM_DATA_INTER } from '../../data/message/constant';
import {
  EVENT_ITEM_DATA_RAW_INTER,
  REACTION_RAW_TYPE,
} from '../../data/event/constant';
import { SessionItemRoomEventContainer } from '../session-item-room-event';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA_INTER;
  sessionId: string;
  sessionToken: string;
  chatMessage: MESSAGE_ITEM_DATA_INTER[];
  sendMessage: Function;
  setChatMessage: Function;
  event?: EVENT_ITEM_DATA_RAW_INTER;
  reservationButtonClick: Function;
  sessionEnd: boolean;
  sessionStart: boolean;
  button?: EVENT_ITEM_DATA_RAW_INTER;
  banner?: EVENT_ITEM_DATA_RAW_INTER;
  reaction?: REACTION_RAW_TYPE;
  sendReaction: (emoji: string) => void;
  reservationCreated?: RESERVATION_CREATED_TYPE;
  question?: QUESTION_TYPE;
  eventStarted: boolean;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  sessionId,
  sessionToken,
  chatMessage,
  sendMessage,
  event,
  setChatMessage,
  reservationButtonClick,
  sessionStart,
  sessionEnd,
  banner,
  button,
  reaction,
  sendReaction,
  reservationCreated,
  question,
  eventStarted,
}) => {
  useEffect(() => {
    if (data?.webinar?.pixelId) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );

      fbq('init', data.webinar.pixelId);
      fbq('track', 'PageView');
      fbq('track', 'ViewContent');
    }
    // Инициализация Pixel
  }, [data?.webinar?.pixelId]);
  return (
    <Container>
      <ViewerItemDataContainer />
      <SessionItemRoomScenarioContainer
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        errorMessage={errorMessage}
      />
      <SessionItemRoomHeaderContainer
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        errorMessage={errorMessage}
        sessionStart={sessionStart}
      />
      <SessionItemRoomChatContainer
        chatMessage={chatMessage}
        sendMessage={sendMessage}
        setChatMessage={setChatMessage}
        eventStarted={eventStarted}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <FlexElem
        spacing={4}
        style={{
          flexDirection: 'column',
          height: 'calc(100%)',
          maxHeight: '100%',
          maxWidth: '100%',
        }}
      >
        <SessionItemRoomVideoContainer
          isLoading={isLoading}
          isSuccess={isSuccess}
          data={data}
          sessionStart={sessionStart}
        />

        <GridElem>
          <SessionItemRoomEventContainer
            button={button}
            banner={banner}
            reaction={reaction}
            sendReaction={sendReaction}
            question={question}
            reservationCreated={reservationCreated}
            sessionId={sessionId}
            modal
            webinarData={data?.webinar}
            reservation={event}
            reservationButtonClick={reservationButtonClick}
          />
        </GridElem>
      </FlexElem>
    </Container>
  );
};

const Container = styled(GridElem)`
  height: 100%;
  min-height: 100%;
  grid-template-columns: 28% auto;
  grid-template-rows: 90px calc(100% - 110px);
  box-sizing: border-box;
  gap: ${Spacing(5)};
  .plyr__controls__item {
    /* display: none; */
  }

  .plyr {
    height: 100%;
  }

  @media screen and (width<1060px) {
    grid-template-columns: auto;
    gap: ${Spacing(3)};
    video {
      width: 100% !important;
      height: auto !important;
      max-height: 100% !important;
    }
    grid-template-rows: min-content min-content min-content min-content;
  }

  @media screen and (width<600px) {
    grid-template-rows: min-content min-content min-content auto;
  }
`;
