import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ sessionId, sort, type, person, pageParam }: any) => {
  let params = '';
  if (sort) params += `&sort=${sort}`;
  // if (type) params += `&type=${type}`;
  // if (person) params += `&person=${person.value}`;

  if (pageParam) params += `&skip=${pageParam}`;
  if (type) {
    type?.map((item: any) => (params += `&type=${item.value}`));
  }

  if (person) {
    person?.map((item: any) => (params += `&person=${item.value}`));
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(sessionId, params),
  });
};
