import React from 'react';

import { RouteComponentProps } from 'react-router';
import { AuthRecoveryContainer } from '../../epic/auth-recovery';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { LayoutAuthElem } from '../../common/layout-auth';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutAuthElem>
          <AuthRecoveryContainer />
        </LayoutAuthElem>
      </LayoutAppElem>
    </PageElem>
  );
};
