import React, { useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';

import { Component } from './component';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { action as fetch, getViewer as getViewerById } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import {
  required,
  maxLength,
  name,
  userName,
} from '../../lib/validation/service';
import { UTMObject, VIEWER_ITEM_DATA_INTER } from '../../data/viewer/constant';
import { WEBINAR_ITEM_DATA_RAW_INTER } from '../../data/webinar/constant';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';
import { HttpRequest } from '../../lib/http';
import { EVENT_ITEM_DATA_RAW_INTER } from '../../data/event/constant';
import { filterUTM } from '../../data/viewer/convert';

export const Container: React.FC<{
  sessionId: string;
  modal?: boolean;
  webinarData?: WEBINAR_ITEM_DATA_RAW_INTER;
  event?: EVENT_ITEM_DATA_RAW_INTER;
  reservationButtonClick?: Function;
  query?: UTMObject;
}> = ({
  sessionId,
  modal = false,
  webinarData,
  event,
  reservationButtonClick,
  query,
}) => {
  const [formVisible, setFormVisible] = useState(false);

  const reactQuery = useQueryClient();

  const onSuccess = (d: any, values: any) => {
    reactQuery.invalidateQueries(MODULE_NAME);
    reactQuery.invalidateQueries(VIEWER_ITEM_DATA_MODULE_NAME);

    setFormVisible(false);
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => fetch(sessionId, values),
    {
      onSuccess,
    },
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, userName, maxLength(80)],
    [FORM_VALUE_ENUM.PHONE]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const preFetch = modal
    ? useQuery([VIEWER_ITEM_DATA_MODULE_NAME], {
        enabled: !!HttpRequest.defaults.headers.common['session_token'],
      })
    : useQuery([MODULE_NAME], () => getViewerById(sessionId), {
        refetchInterval: 120000,
      });

  const isSuccessFecth = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const getData = (): VIEWER_ITEM_DATA_INTER | undefined => {
    const data = preFetch.data as unknown as VIEWER_ITEM_DATA_INTER;
    if (data) {
      return data;
    }
  };

  const data = getData();

  const initialValues =
    isSuccessFecth() && data
      ? {
          [FORM_VALUE_ENUM.NAME]: data?.name,
          [FORM_VALUE_ENUM.PHONE]: data?.phone,
          [FORM_VALUE_ENUM.LANG]: navigator.language,
          [FORM_VALUE_ENUM.UTM]: {},
        }
      : {
          [FORM_VALUE_ENUM.NAME]: '',
          [FORM_VALUE_ENUM.PHONE]: '',
          [FORM_VALUE_ENUM.LANG]: navigator.language,
          [FORM_VALUE_ENUM.UTM]: filterUTM(query),
        };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,

    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(values);
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled()}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      formVisible={formVisible}
      setFormVisible={setFormVisible}
      modal={modal}
      webinarData={webinarData}
      data={data}
      isSuccessFecth={isSuccessFecth()}
      event={event}
      reservationButtonClick={reservationButtonClick}
    />
  );
};
