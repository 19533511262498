import React, { useState } from 'react';
import { GridElem } from '../../../common/grid';
import { AuthRecoveryResendContainer } from '../../auth-recovery-resend';

export const ResendComponent: React.FC<{ email: string }> = ({ email }) => {
  const [active, setActive] = useState<boolean | null>(null);

  const handleSetActive = (status: boolean) => () => {
    setActive(status);
  };

  return (
    <GridElem size="mod">
      <AuthRecoveryResendContainer
        actionCb={handleSetActive(false)}
        email={email}
        active={active}
        handleSetActive={handleSetActive}
      />
    </GridElem>
  );
};
