import React, { useState } from 'react';
import { GridElem } from '../../../common/grid';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { MESSAGE_ITEM_DATA_RAW_INTER } from '../constant';
import { AvatarElem } from '../../../common/avatar';
import { FlexElem } from '../../../common/flex';
import { TextElem } from '../../../common/text';
import { Modal } from '../../../common/modal/elem';
import { ReactComponent as CloseIcon } from '../../../asset/svg/common/close.svg';
import { ReactComponent as ReplyIcon } from '../../../asset/svg/session/room/chat/reply.svg';
import { COLOR_ENUM } from '../../../theme/color';

export const scrollToMessage = (messageId: string) => {
  const element = document.getElementById(messageId);
  if (element) {
    element.scrollIntoView({ block: 'center' });
  }
};

const Elem: React.FC<{
  data: MESSAGE_ITEM_DATA_RAW_INTER;
  setReply: Function;
  elemId: string;
}> = ({ data, setReply, elemId }) => {
  const { content, id, viewer, person, createDate, replyTo } = data;
  const [modalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const getName = (message?: MESSAGE_ITEM_DATA_RAW_INTER) =>
    message?.viewer?.name || message?.person?.name;

  const name = getName(data);
  const replyName = getName(data?.replyTo);
  const photo = viewer?.photo?.url || person?.photo?.url;
  console.log(viewer?.location);
  const [country, all, city] = viewer?.location[0]?.split(',') || [
    'Null',
    'Null',
    'Null',
  ];
  const adress = `${city}, ${country}` || `${person?.city}, ${person?.country}`;

  function formatPhoneNumber(phoneNumber: string | undefined): string {
    if (phoneNumber) {
      // Убираем пробелы и разделяем строку на части
      const part1 = phoneNumber.slice(0, 4); // +380
      const part2 = phoneNumber.slice(4, 7); // 675
      const part3 = phoneNumber.slice(7, 10); // 658
      const maskedPart = '***'; // Маскированная часть

      return `${part1} ${part2} ${part3} ${maskedPart}`;
    } else {
      return '';
    }
  }

  const phone = formatPhoneNumber(viewer?.phone || person?.phone);

  const handleSetReply = () => {
    setReply({ id, content: content.trim() + '...', name });
  };

  return (
    <Container id={elemId}>
      <ModalStyled isOpen={modalVisible} onDidDismiss={closeModal}>
        <ModalContent spacing={3}>
          <AvatarElem size={76} userAvatar={photo} className="avatar" />
          <ModalText spacing={5}>
            <GridElem spacing={2}>
              <TextElem type="semi-bold" size="main">
                {name}
              </TextElem>
              <TextElem size="semiSmall" color="textPrimary">
                {phone}
              </TextElem>
              <TextElem type="medium" color="textDefault">
                {adress}
              </TextElem>
            </GridElem>
            <CloseIconStyled onClick={closeModal} />
          </ModalText>
        </ModalContent>
      </ModalStyled>
      <ModalWraper onClick={openModal}>
        <AvatarElem size={20} userAvatar={photo} className="avatar" />
      </ModalWraper>
      <GridElem spacing={1}>
        <HeadContainer>
          <ModalWraper onClick={openModal}>
            <TextElem type="medium" color="textPrimary" size="mobileDefault">
              {name}
            </TextElem>
          </ModalWraper>

          <TextElem type="medium" color="textPrimary" size="mobileDefault">
            {createDate}
          </TextElem>
        </HeadContainer>
        {replyTo && (
          <ReplyToContainer onClick={() => scrollToMessage(replyTo.id)}>
            <GridElem spacing={1}>
              <FlexElem spacing={1}>
                <TextElem
                  type="medium"
                  color="textPrimary"
                  size="mobileDefault"
                >
                  {replyName}
                </TextElem>
                <ReplyIcon />
              </FlexElem>

              <TextElem
                color="textFourth"
                style={{ wordBreak: 'break-all' }}
                size="mobileDefault"
              >
                {replyTo.content.trim() + '...'}
              </TextElem>
            </GridElem>
          </ReplyToContainer>
        )}
        <TextElem
          color="textFourth"
          style={{ wordBreak: 'break-all' }}
          size="mobileDefault"
        >
          {content}
        </TextElem>
      </GridElem>
      <ReplyContainer onClick={handleSetReply}>
        <ReplyIcon />
      </ReplyContainer>
    </Container>
  );
};

const ModalWraper = styled.div`
  cursor: pointer;
`;

const ReplyToContainer = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(3)};
  width: fit-content;
`;

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const ReplyContainer = styled.div`
  cursor: pointer;
  height: 100%;
  :hover {
    svg {
      path {
        transition: all 0.2s;
      }

      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
    }
  }
`;

const ModalStyled = styled(Modal)`
  ::part(content) {
    padding: ${Spacing(6)} ${Spacing(5)};
    min-width: 300px;
    width: fit-content;
    top: auto;
  }
`;

const ModalContent = styled(GridElem)`
  grid-template-columns: 76px auto;
`;

const ModalText = styled(GridElem)`
  grid-template-columns: auto 16px;
`;

const HeadContainer = styled(FlexElem)`
  justify-content: space-between;
`;

const Container = styled(GridElem)`
  grid-template-columns: 20px auto 14px;
  gap: ${Spacing(2)};
  @media screen and (width<420px) {
    span {
      font-size: 11px !important;
    }
    .avatar {
      height: 16px !important;
      width: 16px !important;
    }
  }
`;

export { Elem as MessageItemElem };
