import React from 'react';
import styled from 'styled-components';

import { DoubleContainerElem } from '../../common/double-container';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { Spacing } from '../../theme';

export const Skeleton: React.FC<{}> = ({}) => {
  return (
    <DoubleContainerElem>
      <SkeletonField />
    </DoubleContainerElem>
  );
};

const SkeletonField = styled(SkeletonFieldElem)`
  .field {
    height: 133px;
  }
  gap: ${Spacing(4)};
`;
