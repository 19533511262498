import React from 'react';

import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';

import { Spacing } from '../../theme';
import { SIZE_LAYOUT_TYPE } from '../../theme/size';
import { FlexElem } from '../../common/flex';
import { COLOR_ENUM } from '../../theme/color';
import { TabElem } from '../../common/tab';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';
import icon from '../../asset/svg/header/admin.svg';
import iconLight from '../../asset/svg/header/admin-light.svg';
import { ADMIN_USER_PAGE_PATH } from '../../page/admin-user';
import { ADMIN_PROJECT_PAGE_PATH } from '../../page/admin-project';
import { ADMIN_FILE_PAGE_PATH } from '../../page/admin-file';

export const Component: React.FC<{
  size?: SIZE_LAYOUT_TYPE;
}> = ({ size = 'small' }) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <Container size={size}>
      <FlexElem spacing={3}>
        <img src={theme.type === THEME_ENUM.LIGHT ? iconLight : icon} />
        <TextElem tid="ADMIN.HEADER" type="semi-bold" size="heading"></TextElem>
      </FlexElem>
      <TabElem
        tabList={[
          {
            tid: 'ADMIN.TAB.USER',
            path: ADMIN_USER_PAGE_PATH,
          },
          {
            tid: 'ADMIN.TAB.PROJECT',
            path: ADMIN_PROJECT_PAGE_PATH,
          },
          {
            tid: 'ADMIN.TAB.FILE',
            path: ADMIN_FILE_PAGE_PATH,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div<{
  size: SIZE_LAYOUT_TYPE;
}>`
  display: grid;
  gap: ${Spacing(6)};
  align-items: center;
  width: 100%;
  /* @media screen and (max-width: 900px) {
    display: none;
  } */
  margin: auto;
`;
