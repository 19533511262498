import React from 'react';

import { Component } from './component';

export const Container: React.FC<{
  setPerson: Function;
  person: string;
  setSort: Function;
  sort: string;
  type: string;
  setType: Function;
  sessionId: string;
}> = ({ person, setPerson, sort, setSort, type, setType, sessionId }) => {
  return (
    <Component
      sort={sort}
      setSort={setSort}
      person={person}
      setPerson={setPerson}
      type={type}
      setType={setType}
      sessionId={sessionId}
    />
  );
};
