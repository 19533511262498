export const MODULE_NAME = 'USER_ITEM_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: (id: string) => `user/admin/user/${id}`,
  },
};
