import React from 'react';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';

import { ProjectListContainer } from '../../epic/project-list';
import { AdminHeaderContainer } from '../../epic/admin-header';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem tab>
        <MenuAppContainer />

        <LayoutElem>
          <AdminHeaderContainer />

          <LayoutSectionElem spacing={8}>
            <ProjectListContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
