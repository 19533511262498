import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, DATA_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { convertExpenditureList } from '../../data/expenditure/convert';
import { EXPENDITURE_ITEM_DATA_RAW_INTER } from '../../data/expenditure/constant';

export const Container: React.FC<{}> = ({}) => {
  const [status, setStatus] = useState('');

  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME],
    queryFn: ({ pageParam = 1 }) =>
      action({
        pageParam,
        status,
      }),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : undefined;
    },
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    return convertExpenditureList(
      preFetch?.data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page];
      }, []) as EXPENDITURE_ITEM_DATA_RAW_INTER[],
    );
  }, [preFetch?.data]);

  useEffect(() => {
    preFetch.refetch();
  }, [status]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      status={status}
      setStatus={setStatus}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      hasNextPage={preFetch.hasNextPage}
    />
  );
};
