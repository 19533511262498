import React from 'react';
import styled, { css } from 'styled-components';
import { SelectElem } from '../../../common/select';
import { MODULE_NAME, SORT_OPTION_LIST } from '../constant';
import { COLOR_ENUM } from '../../../theme/color';

export const SortSelect: React.FC<{
  setSort: Function;
  sort: any;
}> = ({ setSort, sort }) => {
  const onChange = (name: string, value: any) => {
    setSort(value.value);
  };
  return (
    <Container className="sort">
      <SelectCustom
        name={`${MODULE_NAME}_SORT`}
        onChange={onChange}
        options={SORT_OPTION_LIST}
        noAlertContainer
        defaultValue={SORT_OPTION_LIST[1]}
        placeholder="EVENT.FILTER.SORT.PLACEHOLDER"
      />
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
`;

const SelectCustom = styled(SelectElem)`
  .Select__value-container--is-multi {
    display: flex;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};

    width: 119px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* .Select__value-container--has-value {
    display: block;
  } */

  .Select__menu {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .Select__control {
    margin: auto;
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;
