import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { WebinarItemCreateContainer } from '../../epic/webinar-item-create';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />
        <LayoutElem>
          <HeaderContainer path title="WEBINAR.CREATE.HEADER" />

          <LayoutSectionElem spacing={8}>
            <WebinarItemCreateContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
