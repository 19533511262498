import React, { useState } from 'react';
import { startAction, stopAction } from './action';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import styled from 'styled-components';
import { SESSION_DATA_MODULE_NAME } from '../session-data';
import { getSession } from '../session-item-data/action';
import {
  SESSION_ITEM_DATA_INTER,
  SESSION_ITEM_DATA_RAW_INTER,
} from '../../data/session/constant';
import { convertSession } from '../../data/session/convert';
import stopIcon from '../../asset/svg/session/moderation/stop.svg';
import startIcon from '../../asset/svg/session/moderation/start.svg';
import { ContentContainerElem } from '../../common/content-container';
import { COLOR_ENUM } from '../../theme/color';

export const Container: React.FC<{
  sessionId: string;
}> = ({ sessionId }) => {
  const [sesionStarted, setSessionStarted] = useState<boolean>(false);

  const onSuccess = (d: any) => {
    const data: SESSION_ITEM_DATA_INTER =
      d as unknown as SESSION_ITEM_DATA_INTER;

    setSessionStarted(data?.sessionStarted);
  };
  const preFetch = useQuery(
    [SESSION_DATA_MODULE_NAME, sessionId],
    () => getSession(sessionId),
    {
      retry: 0,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess,
    },
  );
  const getData = (): SESSION_ITEM_DATA_INTER | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA_RAW_INTER;

    if (data) {
      return convertSession(data);
    }
  };

  const data = getData();

  const queryClient = useQueryClient();

  const stopMutate = useMutation(() => stopAction(sessionId), {
    onSuccess,
  });

  const startMutate = useMutation(() => startAction(sessionId), {
    onSuccess,
  });

  const isLoading = () => {
    if (startMutate.isLoading || stopMutate.isLoading) {
      return true;
    }
  };

  const onClickStart = (e: any) => {
    startMutate.mutate();
  };

  const onClickStop = (e: any) => {
    stopMutate.mutate();
  };

  const isError = () => {
    if (
      (stopMutate.isError && !stopMutate.isLoading && getErrorMessage()) ||
      (startMutate.isError && !startMutate.isLoading && getErrorMessage())
    ) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER =
      (startMutate.error as ACTION_ERROR_INTER) || stopMutate;
    if (error) {
      return error.message;
    }
  };
  return (
    <ContentContainerElem>
      {isLoading() && <LoaderElem />}

      {sesionStarted ? (
        <ButtonStop
          onClick={onClickStop}
          color="error"
          tid="SESSION.MODERATION.BUTTON_BLOCK.STOP"
          iconRight={stopIcon}
        />
      ) : (
        <ButtonStart
          onClick={onClickStart}
          tid="SESSION.MODERATION.BUTTON_BLOCK.START"
          iconRight={startIcon}
          color="activeBackground"
        />
      )}
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </ContentContainerElem>
  );
};

const ButtonStart = styled(ButtonElem)`
  & > * > * {
    color: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
  }
`;
const ButtonStop = styled(ButtonElem)``;
