import { API, FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const action = (payload: FORM_VALUE_INTER, id: string) => {
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(id),
    data: payload,
  });
};
