import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { FlexElem } from '../../../common/flex';
import { ReactComponent as DotIcon } from '../../../asset/svg/session/dot.svg';
import EditIcon from '../../../asset/svg/session/edit.svg';
import { ButtonElem } from '../../../common/button';
import { COLOR_ENUM } from '../../../theme/color';
import styled from 'styled-components';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const FakeField: React.FC<{
  name?: string;
  phone?: string;
  setFormVisible: Function;
}> = ({ name, phone, setFormVisible }) => {
  return (
    <Container spacing={2}>
      <FakeFieldEl spacing={2} style={{ alignItems: 'center' }}>
        <div style={{ display: 'inline-grid', maxWidth: 'calc(100% - 120px)' }}>
          <TextElem oneLine>{`${name}`}</TextElem>
        </div>
        <DotIcon style={{ minWidth: '4px' }} />
        <div style={{ display: 'flex', maxWidth: '120px' }}>
          <TextElem oneLine>{`${phone}`}</TextElem>
        </div>
      </FakeFieldEl>

      <ButtonElem
        color="backgroundThird"
        tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.TITLE"
        iconRight={EditIcon}
        style={{ width: 'auto' }}
        onClick={() => setFormVisible(true)}
        type="button"
      />
    </Container>
  );
};

const FakeFieldEl = styled(FlexElem)`
  height: 46px;
  padding: 0 20px;
  border-radius: 12px;
  justify-content: center;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const Container = styled(FlexElem)`
  @media screen and (width<472px) {
    flex-wrap: wrap;
    & > * {
      width: 100% !important;
    }
  }
`;
