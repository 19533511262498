import React from 'react';
import styled from 'styled-components';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';

import { ReactComponent as ColapseIcon } from '../../asset/svg/common/colapse.svg';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { CardContainer, DeleteIconStyled } from './component';

export const Skeleton: React.FC<{}> = ({}) => {
  return (
    <>
      <TextElem
        tid="WEBINAR.UPDATE.SUBTOPIC.TITLE"
        type="semi-bold"
        size="main"
      />

      <GridElem spacing={3}>
        {[1, 1, 1, 1].map((item: number, index: number) => (
          <CardContainer style={{ cursor: 'auto' }}>
            <Card>
              <SkeletonFieldElem title={false} />
              <DeleteIconStyled />
            </Card>

            <ColapseIcon />
          </CardContainer>
        ))}

        <DoubleContainerElem>
          <SkeletonFieldElem title={false} />
        </DoubleContainerElem>
      </GridElem>
    </>
  );
};

const Card = styled.div`
  position: relative;
  cursor: auto;
`;
