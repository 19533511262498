import React from 'react';

import { Component } from './component';

export const Container: React.FC<{
  // setSession: Function;
  // session: string;
  setSort: Function;
  sort: string;
  search: string;
  setSearch: Function;
  sessionId: string;
}> = ({ sort, setSort, search, setSearch, sessionId }) => {
  return (
    <Component
      sort={sort}
      setSort={setSort}
      // session={session}
      // setSession={setSession}
      search={search}
      setSearch={setSearch}
      sessionId={sessionId}
    />
  );
};
