import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({
  sessionId,
  sort,
  search,
  // session,
  pageParam,
}: any) => {
  let params = '';
  if (search) params += `&search=${search}`;
  if (sort) params += `&sort=${sort}`;

  if (pageParam) params += `&skip=${pageParam}`;

  // if (session) {
  //   params += `&session=${session.value}`;
  // }
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(sessionId, params),
  });
};
