import React, { useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import {
  maxLength,
  name,
  required,
  userName,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { useHistory } from 'react-router';
import { SESSION_ITEM_ROOM_PAGE_PATH_DYNAMIC } from '../../page/session-item-room';
import { setSessionTokenToLocal } from '../../data/auth/action';
import { useDispatch } from '../../lib/store';
import { filterUTM } from '../../data/viewer/convert';

export const Container: React.FC<{ sessionId: string; query: any }> = ({
  sessionId,
  query,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSuccess = (d: any, values: any) => {
    dispatch(setSessionTokenToLocal(sessionId, d.accessToken)).then(() => {
      history.push(SESSION_ITEM_ROOM_PAGE_PATH_DYNAMIC(sessionId));
    });
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => fetch(values, sessionId),
    { onSuccess },
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, userName, maxLength(80)],
    [FORM_VALUE_ENUM.PHONE]: [],
    [FORM_VALUE_ENUM.UTM]: [],
    [FORM_VALUE_ENUM.LANG]: [required],
    [FORM_VALUE_ENUM.REDIRECT_LINK]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.NAME]: '',
    [FORM_VALUE_ENUM.PHONE]: '',
    [FORM_VALUE_ENUM.UTM]: filterUTM(query),
    [FORM_VALUE_ENUM.LANG]: navigator.language,
    [FORM_VALUE_ENUM.REDIRECT_LINK]:
      SESSION_ITEM_ROOM_PAGE_PATH_DYNAMIC(sessionId),
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(values);
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
    />
  );
};
