//@ts-nocheck

import moment from 'moment';
import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';
export const convert = (data: FORM_VALUE_INTER) => {
  data[FORM_VALUE_ENUM.DATE_CONVERT] = data[FORM_VALUE_ENUM.DATE];
  if (!data[FORM_VALUE_ENUM.AUTO]) {
    data[FORM_VALUE_ENUM.DATE_CONVERT] = new Date(
      data[FORM_VALUE_ENUM.DATE_CONVERT],
    );
  }
  if (data[FORM_VALUE_ENUM.AUTO]) {
    // const dateTime =
    //   new Date().toISOString().slice(0, -8).split('T')[0] +
    //   'T' +
    //   data[FORM_VALUE_ENUM.DATE_CONVERT];

    // // Создаем дату в UTC
    // const [hours, minutes] = data[FORM_VALUE_ENUM.DATE_CONVERT].split(':');
    // const dateParts = dateTime.split('T')[0].split('-');
    // const dateTimeUTC = new Date(
    //   Date.UTC(
    //     dateParts[0],
    //     dateParts[1] - 1, // месяцы в JavaScript начинаются с 0
    //     dateParts[2],
    //     hours,
    //     minutes,
    //   ),
    // );

    // // Проверяем, меньше ли дата текущего времени в UTC
    // if (dateTimeUTC < new Date()) {
    //   // Если да, устанавливаем следующий день в UTC
    //   const nextDay = new Date(
    //     Date.UTC(
    //       dateTimeUTC.getUTCFullYear(),
    //       dateTimeUTC.getUTCMonth(),
    //       dateTimeUTC.getUTCDate() + 1,
    //       dateTimeUTC.getUTCHours(),
    //       dateTimeUTC.getUTCMinutes(),
    //     ),
    //   );
    //   data[FORM_VALUE_ENUM.DATE_CONVERT] =
    //     nextDay.toISOString().slice(0, -8).split('T')[0] +
    //     'T' +
    //     data[FORM_VALUE_ENUM.DATE_CONVERT];
    // } else {
    //   data[FORM_VALUE_ENUM.DATE_CONVERT] =
    //     dateTimeUTC.toISOString().slice(0, -8).split('T')[0] +
    //     'T' +
    //     data[FORM_VALUE_ENUM.DATE_CONVERT];

    const now = new Date();

    // Разбиваем переданное время
    const [hours, minutes, seconds] = data[FORM_VALUE_ENUM.DATE_CONVERT]
      .split(':')
      .map(Number);

    // Создаем дату и время на основе текущей даты и переданного времени
    let dateTime = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        hours,
        minutes,
        seconds,
      ),
    );

    // Форматируем дату в нужный формат
    const formattedDateTime = dateTime
      .toISOString()
      .slice(0, -5)
      .replace('T', 'T');

    data[FORM_VALUE_ENUM.DATE_CONVERT] = formattedDateTime;
  }
  return {
    ...data,
    [FORM_VALUE_ENUM.DATE_CONVERT]: data[FORM_VALUE_ENUM.AUTO]
      ? data[FORM_VALUE_ENUM.DATE_CONVERT]
      : moment(data[FORM_VALUE_ENUM.DATE_CONVERT]).format(
          'YYYY-MM-DDTHH:mm:ss.SSSS',
        ),
    [FORM_VALUE_ENUM.ONLINE_MAX]: Number(data[FORM_VALUE_ENUM.ONLINE_MAX]),
    [FORM_VALUE_ENUM.ONLINE_MIN]: Number(data[FORM_VALUE_ENUM.ONLINE_MIN]),
  };
};
