import React from 'react';
import styled, { css } from 'styled-components';
import { CheckBoxElem } from '../../../common/checkbox';
import { ToggleElem } from '../../../common/toggle';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';

export const OnlineSwitch: React.FC<{
  setOnline: Function;
  online: boolean;
}> = ({ online, setOnline }) => {
  const handleClick = () => {
    setOnline((e: boolean) => !e);
  };
  return (
    <Container className="OnlineSwitch" onClick={handleClick}>
      <ToggleElem checked={online} tid="VIEWER.FILTER.ONLINE" />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  :hover {
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  cursor: pointer;
  background: ${({ theme }) =>
    theme[COLOR_ENUM.DEFAULT] === '#1B1924'
      ? theme[COLOR_ENUM.INPUT_BACKGROUND]
      : theme[COLOR_ENUM.INPUT]};
  padding: 0 ${Spacing(4)};
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
`;
