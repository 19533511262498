import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { FieldPasswordElem } from '../../common/field-password';
import { SelectElem } from '../../common/select';
import { USER_ROLE_OPTION_LIST } from '../../data/user/constant';
import { FORM_VALUE_ENUM } from '../user-item-create/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  setValue: Function;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
  setFieldValue,
  setValue,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <TitleContainer>
          <TextElem tid="USER.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer>
        <DoubleContainerElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="USER.UPDATE.FORM.EMAIL"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
          />
          <SelectElem
            name={FORM_VALUE_ENUM.ROLE}
            onChange={setFieldValue}
            options={USER_ROLE_OPTION_LIST}
            title="USER.UPDATE.FORM.ROLE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
            error={isFieldError(FORM_VALUE_ENUM.ROLE)}
            value={setValue(USER_ROLE_OPTION_LIST, FORM_VALUE_ENUM.ROLE)}
          />
        </DoubleContainerElem>
        <DoubleContainerElem>
          <FieldPasswordElem
            name={FORM_VALUE_ENUM.PASSWORD}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="USER.UPDATE.FORM.PASSWORD"
            value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
            error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
          />
          <FieldTextElem
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="USER.UPDATE.FORM.NAME"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />
        </DoubleContainerElem>
        <DoubleContainerElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.TELEGRAM_ID}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            title="USER.CREATE.FORM.TELEGRAM_ID"
            value={getFieldValue(FORM_VALUE_ENUM.TELEGRAM_ID)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.TELEGRAM_ID)}
            error={isFieldError(FORM_VALUE_ENUM.TELEGRAM_ID)}
          />
          <ButtonElem
            type="submit"
            tid="USER.UPDATE.BUTTON"
            // fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
            style={{ marginTop: '26px' }}
          />
        </DoubleContainerElem>
        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};

const ButtonStyled = styled(ButtonElem)`
  margin-top: 26px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;
