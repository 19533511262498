import React from 'react';
import styled from 'styled-components';
import { GridElem } from '../../common/grid';
import { DoubleContainerElem } from '../../common/double-container';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { Spacing } from '../../theme';

export const Skeleton: React.FC<{}> = ({}) => {
  return (
    <>
      <GridElem spacing={3}>
        {[1, 1].map((item: number, index: number) => (
          <DoubleContainerElem>
            <GridElem spacing={4}>
              <SkeletonFieldElem title />
              <SkeletonFieldElem title />
            </GridElem>
            <SkeletonField title />
          </DoubleContainerElem>
        ))}
      </GridElem>
    </>
  );
};

const SkeletonField = styled(SkeletonFieldElem)`
  .field {
    height: 133px;
  }
`;
