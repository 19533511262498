import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const exportToExcel = (id: string) => {
  return HttpRequest({
    method: API.EXPORT_TO_EXCEL.TYPE,
    url: API.EXPORT_TO_EXCEL.URL(id),
    responseType: 'blob',
  });
};

export const importFromExcel = (id: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return HttpRequest({
    method: API.IMPORT_FROM_EXCEL.TYPE,
    url: API.IMPORT_FROM_EXCEL.URL(id),
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
