import {
  EXPENDITURE_ITEM_DATA_INTER,
  EXPENDITURE_ITEM_DATA_RAW_INTER,
  EXPENDITURE_ITEM_LIST_DATA_INTER,
} from './constant';

export const convertExpenditure = (
  expenditure: EXPENDITURE_ITEM_DATA_RAW_INTER,
): EXPENDITURE_ITEM_DATA_INTER => {
  return {
    ...expenditure,
  };
};

export const convertExpenditureList = (
  expenditureList: EXPENDITURE_ITEM_DATA_RAW_INTER[],
): EXPENDITURE_ITEM_LIST_DATA_INTER => {
  return {
    list: expenditureList?.map(
      (expenditure: EXPENDITURE_ITEM_DATA_RAW_INTER) => {
        return convertExpenditure(expenditure);
      },
    ),
    isEmpty: !expenditureList?.length,
  };
};
