import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';

import { AuthRecoveryConfirmContainer } from '../../epic/auth-recovery-confirm';
import queryString from 'query-string';
import { LayoutAuthElem } from '../../common/layout-auth';

export const Page: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const email = query.email;

  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutAuthElem>
          <AuthRecoveryConfirmContainer email={email} />
        </LayoutAuthElem>
      </LayoutAppElem>
    </PageElem>
  );
};
