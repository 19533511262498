import moment from 'moment';
import i18n from 'i18next';
import { addMinutes, format } from 'date-fns';

export const convertDateToList = (date: string): string => {
  const now = moment();
  const inputDate = moment(date);

  // Получаем разницу в формате "X времени назад" только если дата менее чем 7 дней старая
  if (now.diff(inputDate, 'days') < 7) {
    return inputDate.fromNow(); // Локализованная строка типа "1 минуту назад", "5 часов назад"
  } else {
    // Если дата старше 7 дней, выводим просто дату
    return inputDate.format('DD.MM.YYYY'); // Можно изменить формат по своему усмотрению
  }
};
export const convertDateTime = (date: string) => {
  return moment(date).format(i18n.t('LANG.DATA.DATE_TIME'));
};

export const convertDateTimeWithoutZone = (date: string) => {
  return moment(date).utcOffset(0, false).format(i18n.t('LANG.DATA.DATE_TIME'));
};

export const convertDayAndTimeWithoutZone = (date: string) => {
  return moment(date)
    .utcOffset(0, false)
    .format(i18n.t('LANG.DATA.DAY_AND_TIME'));
};

export const convertDayWithoutZone = (date: string) => {
  return moment.utc(date).format(i18n.t('LANG.DATA.DAY'));
};

export const convertTimeWithoutZone = (date: string) => {
  return moment.utc(date).format(i18n.t('LANG.DATA.TIME'));
};

export const convertDate = (date: string) => {
  return moment.utc(date).format(i18n.t('LANG.DATA.DATE'));
};
export const convertDateWithYear = (date: string) => {
  return moment(date).format(i18n.t('LANG.DATA.DATE_WITH_YEAR'));
};

export const convertTime = (date: string) => {
  return moment.utc(date).format(i18n.t('LANG.DATA.ONLY_TIME'));
};

export const convertTimeWithTimeZone = (date: string) => {
  return moment(date).format(i18n.t('LANG.DATA.ONLY_TIME'));
};

export const formatDateToUtc = (date: Date) => {
  try {
    const newDate = format(
      addMinutes(date, date.getTimezoneOffset()),
      "yyyy-MM-dd'T'HH:mm:ss",
    );
    return newDate;
  } catch (error) {
    console.log(error);
  }
};

export const convertDateToCalendarTime = (date: string) => {
  return moment(date)
    .locale('en-gb')
    .calendar({
      sameDay: i18n.t('CONVERT.DATE.CALENDAR_SAME_DAY'),
      lastDay: i18n.t('CONVERT.DATE.CALENDAR_LAST_DAY'),
      lastWeek: i18n.t('CONVERT.DATE.CALENDAR_LAST_WEEK'),
      sameElse: i18n.t('CONVERT.DATE.CALENDAR_SAME_ELSE'),
    });
};

export const convertPaymentItemOrderPeriod = (date: string) => {
  return moment(date).format(i18n.t('CONVERT.PAYMENT_ORDER_PERIOD'));
};
