import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';

import addIcon from '../../asset/svg/button/plus.svg';
import { FileItemUploadContainer } from '../file-item-upload';
import { DoubleContainerElem } from '../../common/double-container';
import { FlexElem } from '../../common/flex';
import CloseIcon from '../../asset/svg/common/close.svg';
import { LoaderElem } from '../../common/loader';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  formVisible: boolean;
  setFormVisible: Function;
  onSuccessUpload: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formVisible,
  setFormVisible,
  onSuccessUpload,
}) => {
  const handleSubmit = () => {
    if (formVisible) {
      formik.handleSubmit();
    } else {
      setFormVisible(true);
    }
  };
  const closeModal = () => {
    formik.resetForm();

    setFormVisible(false);
  };
  return (
    <GridElem>
      {formVisible ? (
        <Container spacing={4}>
          <DoubleContainerElem>
            <GridElem spacing={4}>
              <FlexElem style={{ justifyContent: 'space-between', gap: '8px' }}>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="WEBINAR.UPDATE.GIFT.FORM.NAME"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                  style={{ width: '100%' }}
                />
                <ButtonElem
                  iconLeft={CloseIcon}
                  color="backgroundThird"
                  style={{
                    minWidth: '46px',
                    width: '46px',
                    padding: '15px',
                    marginTop: '26px',
                  }}
                  onClick={closeModal}
                />
              </FlexElem>

              <FieldTextElem
                name={FORM_VALUE_ENUM.DESCRIPTION}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="WEBINAR.UPDATE.GIFT.FORM.DESCRIPTION"
                value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
                error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              />
            </GridElem>
            <GridElem spacing={3}>
              <TextElem
                tid="WEBINAR.UPDATE.GIFT.FORM.PHOTO"
                color="textPrimary"
                style={{ height: '14px' }}
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                name={FORM_VALUE_ENUM.FILE}
                type="image"
                // description={false}
              />
            </GridElem>
          </DoubleContainerElem>

          <DoubleContainerElem>
            <ButtonElem
              tid="Create gift"
              fill="solid"
              color="success"
              onClick={formik.handleSubmit}
              // iconRight={addIcon}
              disabled={isSubmitDisabled()}
            />
          </DoubleContainerElem>
          {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
          {isLoading && <LoaderElem />}
        </Container>
      ) : (
        <DoubleContainerElem>
          <ButtonElem
            tid="WEBINAR.UPDATE.GIFT.BUTTON.CREATE"
            fill="solid"
            color="backgroundThird"
            onClick={() => setFormVisible(true)}
            iconRight={addIcon}
          />
        </DoubleContainerElem>
      )}
    </GridElem>
  );
};

const Container = styled(GridElem)`
  .file-upload-wrapper {
    height: 134px !important;
    min-height: 134px !important;
  }
`;
