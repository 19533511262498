import React from 'react';
import { EVENT_ITEM_DATA_INTER, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const Reservation: React.FC<{
  data: EVENT_ITEM_DATA_INTER;
}> = ({ data }) => {
  const { buttonText } = data;
  return (
    <>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textFourth"
          oneLine
          style={{ paddingLeft: `${Spacing(5)}` }}
        >
          {buttonText}
        </TextElem>
      </EllipsisContainerElem>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textPrimary" size="main" oneLine>
          {/* {person.name} */}
        </TextElem>
      </EllipsisContainerElem>
    </>
  );
};
