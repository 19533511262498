import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { RouteComponentProps } from 'react-router';

import { ViewerListContainer } from '../../epic/viewer-list';
import { SessionHeaderContainer } from '../../epic/session-header';
import { SessionDataContainer } from '../../epic/session-data';

interface SessionViewerPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionViewerPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;
  return (
    <PageElem>
      <LayoutAppElem tab>
        <MenuAppContainer />
        <LayoutElem size="default">
          <SessionDataContainer sessionId={sessionId} />
          <SessionHeaderContainer size="default" sessionId={sessionId} />
          <LayoutSectionElem spacing={8}>
            <ViewerListContainer sessionId={sessionId} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
