import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_LAYOUT_TYPE,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  children: ReactNode;
  size?: SIZE_LAYOUT_TYPE;
  direction?: string;
  fullHeight?: boolean;
  className?: string;
  oneColumn?: boolean;
  style?: object;
}> = ({
  children,
  size = 'small',
  direction = 'column',
  fullHeight = false,
  className,
  oneColumn = false,
  style,
}) => {
  return (
    <Center
      className={className}
      size={size}
      direction={direction}
      fullHeight={fullHeight}
      oneColumn={oneColumn}
      style={style}
    >
      {!(oneColumn || fullHeight) && <Div id="layoutElem" />}

      {children}
    </Center>
  );
};

const Div = styled.div`
  @media screen and (width >1200px) {
    display: none;
  }
`;

const Center = styled.div<{
  size: SIZE_LAYOUT_TYPE;
  direction?: string;
  fullHeight: boolean;
  oneColumn?: boolean;
}>`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: ${Spacing(6)} ${Spacing(3)};

  @media screen and (width> 400px) {
    padding: ${Spacing(7)} ${Spacing(5)};
  }

  @media screen and (width> 1200px) {
    padding: ${Spacing(10)} ${Spacing(10)} ${Spacing(10)} 310px;
  }

  box-sizing: border-box;
  height: ${(props) => (props.fullHeight ? '100%' : 'min-content')};

  gap: ${Spacing(6)};

  display: flex;
  justify-content: ${(props) => (props.oneColumn ? 'center' : '')};

  ${({ direction, size }) => css`
    flex-direction: ${direction};
    max-width: calc(${SIZE_LAYOUT_DATA[size]}px + 40px);

    @media screen and (width> 1200px) {
      max-width: calc(${SIZE_LAYOUT_DATA[size]}px + 350px);
    }
  `};

  ${({ oneColumn, size }) => {
    if (oneColumn) {
      return css`
        #layoutElem {
          display: none;
        }
        @media screen and (width>1200px) {
          max-width: calc(${SIZE_LAYOUT_DATA[size]}px + 80px);
          padding: ${Spacing(10)};
        }
      `;
    }
  }};
`;
