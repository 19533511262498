import CryptoJS from 'crypto-js';
const encryptionKey = process.env.REACT_APP_SECRET_KEY as string;

// Функция для шифрования
export function encryptSecretKey(secretKey: string) {
  const ciphertext = CryptoJS.AES.encrypt(secretKey, encryptionKey).toString();
  return ciphertext;
}

// Функция для дешифрования
export function decryptSecretKey(ciphertext: string) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
  const decryptedSecretKey = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedSecretKey;
}
