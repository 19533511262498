import React from 'react';
import { useLocation } from 'react-router';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { Component } from './component';

export const Container: React.FC = () => {
  const router = useLocation();

  const isActivePath = (...path: string[]) =>
    path.some((el) => router.pathname.includes(el));

  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  return (
    <Component
      theme={theme.type}
      isActivePath={isActivePath}
      pathname={router.pathname}
    />
  );
};
