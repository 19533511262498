import React, { ReactNode } from 'react';
import styled from 'styled-components';
import backgroundImg from '../../asset/img/loginBg.png';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
      <Background />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  padding: ${Spacing(8)};
  @media screen and (width < 900px) {
    grid-template-columns: 1fr;
  }
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: ${Spacing(8)};
`;

const Content = styled.div`
  display: grid;
  width: 100%;
  max-width: 310px;
  margin: auto;
`;

const Background = styled.div`
  background: no-repeat url(${backgroundImg});
  background-size: cover;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  @media screen and (width < 900px) {
    display: none;
  }
`;
