export const MODULE_NAME = 'PROJECT_ITEM_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: (id: string) => `/project/${id}`,
  },
};
