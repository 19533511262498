export const MODULE_NAME = 'WEBINAR_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  // USER = 'user',
  NAME = 'name',
  PROJECT = 'project',
  // SPEAKER_IMG = 'speakerImg',
  // LOGO = 'logo',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  // [FORM_VALUE_ENUM.USER]: string;
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PROJECT]: string;
  // [FORM_VALUE_ENUM.SPEAKER_IMG]: string;
  // [FORM_VALUE_ENUM.LOGO]: string;
}

export const API = {
  TYPE: 'POST',
  URL: (projectId: string) => `/webinar/${projectId}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
