export enum CURRENCY_TYPE {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
}

export const CURRENCY_TYPE_SYMBOL = {
  [CURRENCY_TYPE.USD]: '$',
  [CURRENCY_TYPE.EUR]: '€',
  [CURRENCY_TYPE.UAH]: '₴',
};
