import React from 'react';
import { EVENT_ITEM_DATA_INTER, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import styled from 'styled-components';

export const BannerMobile: React.FC<{
  data: EVENT_ITEM_DATA_INTER;
}> = ({ data }) => {
  const { link, file } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textFourth" oneLine>
          {link}
        </TextElem>
      </EllipsisContainerElem>
      <Image src={file.url} />
    </>
  );
};

const Image = styled.img`
  height: 40px;
  border-radius: 8px;
`;
