import { STEPPER_ITEM } from '../../common/stepper/elem';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { EVENT_ITEM_DATA_RAW_INTER } from '../event/constant';
import { WEBINAR_ITEM_DATA_RAW_INTER } from '../webinar/constant';

export interface SESSION_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  name: string;
  onlineUserMin: number;
  onlineUserMax: number;
  date: string;
  timeZone: string;
  serverDate: string;
  availableSeats: number;
  userOnline: number;
  sessionStarted: boolean;
  // sessionEnd: boolean;
  webinar: WEBINAR_ITEM_DATA_RAW_INTER;
  auto: boolean;
  sessionIsOver?: boolean;
  status: SESSION_STATUS;
  event: EVENT_ITEM_DATA_RAW_INTER[];
  timecode: STEPPER_ITEM[];
}

export enum SESSION_STATUS {
  PLAN = 'PLAN',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  AUTO = 'AUTO',
  ALL = 'ALL',
}

export const SESSION_STATUS_COLOR = {
  [SESSION_STATUS.PLAN]: COLOR_ENUM.DEFAULT,
  [SESSION_STATUS.ONGOING]: COLOR_ENUM.ONGOING_STATUS,
  [SESSION_STATUS.COMPLETED]: COLOR_ENUM.SUCCESS,
  [SESSION_STATUS.AUTO]: COLOR_ENUM.DEFAULT,
  [SESSION_STATUS.ALL]: COLOR_ENUM.DEFAULT,
};

export interface SESSION_ITEM_LIST_DATA_RAW_INTER {
  list: SESSION_ITEM_DATA_RAW_INTER[];
}

export interface SESSION_ITEM_DATA_INTER extends SESSION_ITEM_DATA_RAW_INTER {
  statusColor: COLOR_TYPE;
  rawDate: string;
  day: string;
  time: string;
}

export interface SESSION_ITEM_LIST_DATA_INTER {
  list: SESSION_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN_URL;

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: (webinarId: string) => `webinar/${webinarId}/session`,
  },
  VIEWER_DATE: {
    TYPE: 'GET',
    URL: (sessionId: string) => `/session/viewerDateList/${sessionId}`,
  },
};
