import styled from 'styled-components';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useRef } from 'react';
import animation from './Animation.json';

export const Animation: React.FC<{ className?: string }> = ({ className }) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  lottieRef.current?.setSpeed(0.8);
  return (
    <Container className={className}>
      <Lottie
        loop={false}
        animationData={animation}
        lottieRef={lottieRef}
      ></Lottie>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  div {
    width: 100%;
    height: 100%;
  }
  top: 0;
  left: 0;
`;
