import React from 'react';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { action as fetch } from './action';

import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { VIEWER_ITEM_DATA_INTER } from '../../data/viewer/constant';
import { SESION_ITEM_ROOM_EDIT_PROFILE_MODULE_NAME } from '../session-item-room-edit-profile';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';

export const Container: React.FC<{}> = ({}) => {
  const query = useQueryClient();

  const onSuccess = (d: any) => {
    query.invalidateQueries(VIEWER_ITEM_DATA_MODULE_NAME);
  };

  const preFetch = useQuery(VIEWER_ITEM_DATA_MODULE_NAME);

  const getData = (): VIEWER_ITEM_DATA_INTER | undefined => {
    const data = preFetch.data as unknown as VIEWER_ITEM_DATA_INTER;
    if (data) {
      return data;
    }
  };

  const data = getData();

  const action = useMutation(MODULE_NAME, fetch, { onSuccess });
  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const onClick = () => {
    action.mutate();
  };

  return (
    <>
      {isLoading() && <LoaderElem />}
      {data?.needRecord ? (
        <ButtonElem
          color="success"
          fill="outline"
          tid="SESSION.VIEWER.SCENARIO.SUCCESS"
        />
      ) : (
        <ButtonElem onClick={onClick} tid="SESSION.VIEWER.SCENARIO.BUTTON" />
      )}
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
      {isSuccess() && <AlertActionElem type="success" tid="Success" />}
    </>
  );
};
