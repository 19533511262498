import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { RouteComponentProps } from 'react-router';

import { WebinarItemDataContainer } from '../../epic/webinar-item-data';
import { WebinarHeaderContainer } from '../../epic/webinar-header';
import { SessionListContainer } from '../../epic/session-list';

interface WebinarSessionPageProps
  extends RouteComponentProps<{
    webinarId: string;
  }> {}

export const Page: React.FC<WebinarSessionPageProps> = ({ match }) => {
  const webinarId = match.params.webinarId;

  return (
    <PageElem>
      <LayoutAppElem tab>
        <MenuAppContainer />

        <WebinarItemDataContainer webinarId={webinarId} />
        <LayoutElem size="default">
          <WebinarHeaderContainer size="default" webinarId={webinarId} />
          <LayoutSectionElem spacing={8}>
            <SessionListContainer webinarId={webinarId} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
