import React from 'react';
import styled from 'styled-components';
import { GridElem } from '../../common/grid';
import { ContentContainerElem } from '../../common/content-container';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';
import { WebinarItemDeleteContainer } from '../webinar-item-delete';
import { DoubleContainerElem } from '../../common/double-container';
import { ButtonElem } from '../../common/button';
import importIcon from '../../asset/svg/button/import-light.svg';
import { useHistory } from 'react-router';
import { WEBINAR_ITEM_IMPORT_PAGE_PATH_DYNAMIC } from '../../page/webinar-item-import';
import { Spacing } from '../../theme';
import importIconLight from '../../asset/svg/button/import-light-theme.svg';

import { ReactComponent as DeleteIcon } from '../../asset/svg/common/close.svg';
import { CustomInput } from '../../common/field-text/elem';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';
import { THEME_MODULE_NAME } from '../../data/theme';

export const Component: React.FC<{
  webinarId: string;
}> = ({ webinarId }) => {
  const history = useHistory();

  const handleImport = () => {
    history.push(WEBINAR_ITEM_IMPORT_PAGE_PATH_DYNAMIC(webinarId));
  };

  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <GridElem spacing={5}>
      <ContentContainerElem>
        <TextElem
          tid="WEBINAR.UPDATE.SUBTOPIC.ACTION"
          type="semi-bold"
          size="main"
        />
        <DoubleContainerElem>
          <ButtonElem
            tid="WEBINAR.UPDATE.SUBTOPIC.BUTTON.IMPORT"
            fill="solid"
            color="backgroundThird"
            iconRight={
              theme.type === THEME_ENUM.LIGHT ? importIconLight : importIcon
            }
            onClick={handleImport}
          />
          <WebinarItemDeleteContainer webinarId={webinarId} />
        </DoubleContainerElem>
      </ContentContainerElem>
    </GridElem>
  );
};

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto 16px;
  align-items: center;
  gap: ${Spacing(3)};
  cursor: pointer;
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  top: 15px;
  right: 16px;
  position: absolute;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
    }
  }
`;

const Field = styled(CustomInput)``;

const Card = styled.div`
  position: relative;

  input {
    transition: all 0.2s;
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
  :hover {
    input {
      background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
    }
    input:focus-visible {
      background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    }
  }
`;
