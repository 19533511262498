import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FieldTextAreaElem } from '../../../common/field-text-area';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { SelectPreloadContainer } from '../../select-preload';
import { getPersonList } from '../../../data/person/action';
import { convertPersonListToSelect } from '../../../data/person/convert';
import { getNessageList } from '../../../data/message/action';
import { convertMessageToSelect } from '../../../data/message/convert';
import { SelectDynamicContainer } from '../../select-dynamic';
import styled from 'styled-components';
import { Value as SingleValue } from '../../../data/message/frame/select/value';
import { Option } from '../../../data/message/frame/select/option';

export const MessageForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  sessionId: string;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  sessionId,
}) => {
  console.log(formik.values);
  return (
    <GridElem spacing={5}>
      <DoubleContainerElem>
        <SelectDynamicContainer
          name={FORM_VALUE_ENUM.PERSON}
          action={getPersonList}
          convert={convertPersonListToSelect}
          formik={formik}
          title="EVENT.FORM.PERSON"
          onChange={setFieldValue}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PERSON)}
          error={isFieldError(FORM_VALUE_ENUM.PERSON)}
          value={getFieldValue(FORM_VALUE_ENUM.PERSON)}
          param={sessionId}
        />

        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
        />
      </DoubleContainerElem>
      <DoubleContainerElem>
        <Select
          formik={formik}
          value={getFieldValue(FORM_VALUE_ENUM.MESSAGE_REPLY)}
          action={getNessageList}
          name={FORM_VALUE_ENUM.MESSAGE_REPLY}
          convert={convertMessageToSelect}
          param={sessionId}
          customComponents={{ SingleValue, Option }}
          title="EVENT.FORM.MESSAGE_REPLY"
        />
      </DoubleContainerElem>
      <FieldTextAreaElem
        name={FORM_VALUE_ENUM.MESSAGE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.MESSAGE"
        value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
        error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
      />
    </GridElem>
  );
};

const Select = styled(SelectDynamicContainer)`
  /* & .Select__menu {
    top: 65px;
  } */
  & .Select__option {
    height: 48px;
  }
`;
