import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';
import { getProjectList } from '../../data/project/action';
import { SelectPreloadContainer } from '../select-preload';
import { convertProjectListToSelect } from '../../data/project/convert';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import addIcon from '../../asset/svg/button/plus.svg';
import importIcon from '../../asset/svg/button/import-light.svg';
import importIconLight from '../../asset/svg/button/import-light-theme.svg';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  handleImport: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
  handleImport,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <ContentContainerElem>
          <GridElem spacing={5}>
            <FieldTextElem
              name={FORM_VALUE_ENUM.NAME}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="WEBINAR.CREATE.FORM.NAME"
              value={getFieldValue(FORM_VALUE_ENUM.NAME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
              error={isFieldError(FORM_VALUE_ENUM.NAME)}
            />
            {/* <FieldTextElem
              name={FORM_VALUE_ENUM.USER}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="WEBINAR.CREATE.FORM.SPEAKER"
              value={getFieldValue(FORM_VALUE_ENUM.USER)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.USER)}
              error={isFieldError(FORM_VALUE_ENUM.USER)}
            /> */}
            <DoubleContainerElem>
              <SelectPreloadContainer
                name={FORM_VALUE_ENUM.PROJECT}
                action={getProjectList}
                convert={convertProjectListToSelect}
                // formik={formik}
                title="WEBINAR.CREATE.FORM.PROJECT"
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
                error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
                // value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
                placeholder=""
                dynamic
              />
            </DoubleContainerElem>

            {/* <FileItemUploadContainer
              onSuccess={(value: string) =>
                formik.setFieldValue(FORM_VALUE_ENUM.LOGO, value)
              }
            >
              <TextElem tid="WEBINAR.CREATE.FORM.LOGO" />
              <ButtonElem tid="Upload" />
            </FileItemUploadContainer> */}
          </GridElem>
        </ContentContainerElem>

        <DoubleContainerElem>
          <ButtonElem
            type="submit"
            tid="WEBINAR.CREATE.BUTTON.CREATE"
            fill="solid"
            disabled={isSubmitDisabled()}
            iconRight={addIcon}
          />
          <ButtonElem
            type="button"
            onClick={handleImport}
            tid="WEBINAR.CREATE.BUTTON.IMPORT"
            disabled={isSubmitDisabled()}
            iconRight={
              theme.type === THEME_ENUM.LIGHT ? importIconLight : importIcon
            }
            color="backgroundThird"
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};
