import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  convertDate,
  convertDateTime,
  convertDateTimeWithoutZone,
  convertTime,
} from '../../lib/lang/service';
import {
  SESSION_ITEM_DATA_RAW_INTER,
  SESSION_ITEM_DATA_INTER,
  SESSION_ITEM_LIST_DATA_RAW_INTER,
  SESSION_ITEM_LIST_DATA_INTER,
  SESSION_STATUS,
  SESSION_STATUS_COLOR,
} from './constant';

export const convertSession = (
  session: SESSION_ITEM_DATA_RAW_INTER,
): SESSION_ITEM_DATA_INTER => {
  return {
    ...session,
    statusColor: SESSION_STATUS_COLOR[session.status],
    rawDate: convertDateTimeWithoutZone(session.date),
    day: convertDate(session.date),
    time: convertTime(session.date),
    date: convertDateTimeWithoutZone(session.date),
  };
};

export const convertSessionList = (
  sessionList: SESSION_ITEM_LIST_DATA_RAW_INTER,
): SESSION_ITEM_LIST_DATA_INTER => {
  return {
    list: sessionList.list?.map((session: SESSION_ITEM_DATA_RAW_INTER) => {
      return convertSession(session);
    }),
    isEmpty: !sessionList.list?.length,
  };
};

export const convertSessionListToSelect = (
  data: SESSION_ITEM_LIST_DATA_INTER,
) => {
  const newList: SELECT_OPTION_ITEM_DATA[] = [];
  data.list.map((item: SESSION_ITEM_DATA_INTER) => {
    newList.push({
      value: item.id,
      label: item.name.length > 20 ? `${item.name.slice(0, 20)}...` : item.name,
    });
  });

  return newList;
};

export const convertViewerDateList = (list: string[]) => {
  return list.map((item: string) => {
    return { value: item, label: item };
  });
};
