import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { ColorPicker, TimePicker } from 'antd';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';

export const Elem: React.FC<PROPS_TYPE> = ({
  className,
  error,
  errorMessage,
  value,
  onChange,
  onBlur,
  title,
  name,
}) => {
  const handleChange = (e: any) => {
    if (e) {
      e.target = {};
      e.target.name = name;
      e.target.value = `#${e.toHex().toUpperCase()}`;
      if (onChange) {
        onChange(e);
      }
    }
  };
  return (
    <Container className={className} id="field-time-input-container">
      <Content>
        {title && (
          <TextElem
            tid={title}
            color="textPrimary"
            style={{ height: '14px', display: 'block', marginBottom: '12px' }}
          />
        )}
        <StyledColorPicker
          onChange={handleChange}
          showText
          format="hex"
          value={value}
          // mode={['single', 'gradient']}
        />
      </Content>

      {(error || errorMessage) && (
        <TextElem size="alert" color="error">
          {errorMessage}
        </TextElem>
      )}
    </Container>
  );
};

const Content = styled.div`
  input {
    transition: all 0.2s;
  }
  span {
    transition: all 0.2s;
  }

  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
`;

const StyledColorPicker = styled(ColorPicker)`
  height: 46px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  .ant-color-picker-trigger {
    order: 2;
  }

  .ant-color-picker-color-block {
    width: 48px;
    height: 16px;
    border-radius: 8px;
  }

  .ant-color-picker-trigger-text {
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
    /* line-height: 1em; */
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]} !important;
  }

  cursor: pointer;
  height: 46px;
  :focus-visible {
    outline: none;
  }
  position: relative;

  box-shadow: none !important;

  text-align: start;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]} !important;

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.INPUT_TITLE]} !important;
    }
  }

  &:focus-within,
  &:focus,
  &:active,
  &:focus-visible {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]} !important;
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  }

  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};

  padding: 0 ${Spacing(4)};
`;
