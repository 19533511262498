import { API } from './constant';
import { HttpRequest } from '../../lib/http';
import { FORM_VALUE_INTER } from '../person-item-create/constant';

export const updateProject = (id: string, payload: FORM_VALUE_INTER) => {
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(id),
    data: payload,
  });
};
