import React from 'react';

import { RouteComponentProps } from 'react-router';
import { AuthLoginContainer } from '../../epic/auth-login';

import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { LayoutAuthElem } from '../../common/layout-auth';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutAuthElem>
          <AuthLoginContainer />
        </LayoutAuthElem>
      </LayoutAppElem>
    </PageElem>
  );
};
