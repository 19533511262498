import { convertTimeWithTimeZone } from '../../lib/lang/service';
import {
  MESSAGE_ITEM_DATA_INTER,
  MESSAGE_ITEM_DATA_RAW_INTER,
  MESSAGE_ITEM_LIST_DATA_RAW_INTER,
} from './constant';

export const convertMessage = (
  message: MESSAGE_ITEM_DATA_RAW_INTER,
): MESSAGE_ITEM_DATA_INTER => {
  return {
    ...message,
    createDate: convertTimeWithTimeZone(message.createDate),
  };
};

export const convertMessageToSelect = (arr: MESSAGE_ITEM_DATA_RAW_INTER[]) => {
  const newArray: any = [];

  if (arr) {
    arr?.map((message: MESSAGE_ITEM_DATA_RAW_INTER) => {
      newArray.push({
        value: message.id,
        content: message.content,
        title: `${
          message?.person?.name || message?.viewer?.name
        }, ${convertTimeWithTimeZone(message.createDate)}`,
      });
    });
  }
  return newArray;
};

export const convertMessageList = (
  messageList: MESSAGE_ITEM_DATA_RAW_INTER[],
): MESSAGE_ITEM_DATA_INTER[] => {
  return messageList?.map((message: MESSAGE_ITEM_DATA_RAW_INTER) => {
    return convertMessage(message);
  });
};
