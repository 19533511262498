export const MODULE_NAME = 'SESSION_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  SESSION = 'session',
  PERSON = 'person',
  EVENT = 'event',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.EVENT]: boolean;
  [FORM_VALUE_ENUM.PERSON]: boolean;
  [FORM_VALUE_ENUM.SESSION]: string;
}

export const API = {
  TYPE: 'POST',
  URL: (id: string) => `/session/import/${id}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
