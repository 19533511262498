import React, { useState } from 'react';
import { action as deleteMember } from './action';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DeleteIconElem } from '../../common/delete-icon';
import { ButtonElem } from '../../common/button';
import CloseIcon from '../../asset/svg/common/close.svg';
import { WEBINAR_ITEM_DATA_MODULE_NAME } from '../webinar-item-data';

export const Container: React.FC<{
  webgift: string;
}> = ({ webgift }) => {
  const queryClient = useQueryClient();

  const action = useMutation(() => deleteMember(webgift), {
    onSuccess: () => {
      return queryClient.invalidateQueries(WEBINAR_ITEM_DATA_MODULE_NAME);
    },
  });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const onClick = () => {
    action.mutate();
  };

  return (
    <DeleteIconElem onClick={onClick} isLoading={isLoading()}>
      <ButtonElem
        iconLeft={CloseIcon}
        color="backgroundThird"
        style={{
          minWidth: '46px',
          width: '46px',
          padding: '15px',
          marginTop: '26px',
        }}
      />
    </DeleteIconElem>
  );
};
