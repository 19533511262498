import React from 'react';
import { ButtonBackElem } from '../../common/button-back';
import { TextElem } from '../../common/text';
import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';
import { THEME_ENUM } from '../../data/theme/constant';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_TYPE } from '../../theme/size';
import { COLOR_TYPE } from '../../theme/color';

export const Component: React.FC<{
  path?: string | boolean;
  title?: string;
  icon?: string;
  theme?: THEME_ENUM;
  iconLight?: string;
  iconDark?: string;
  button?: any;
  tvalue?: object | undefined;
  className?: string;
  light: boolean;
  background?: COLOR_TYPE;
  size: SIZE_LAYOUT_TYPE;
}> = ({ path, title, tvalue, icon, size }) => {
  return (
    <Container size={size}>
      {path && <ButtonBackElem path={path} />}
      {icon && <Icon src={icon} />}

      <TextElem
        type="semi-bold"
        size="heading"
        // oneLine={true}
        tid={title}
        tvalue={tvalue}
      />
    </Container>
  );
};

const Container = styled.div<{
  size: SIZE_LAYOUT_TYPE;
}>`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
  width: 100%;

  margin: auto;
  ${({ size }) => css`
    max-width: calc(${SIZE_LAYOUT_DATA[size]}px + ${Spacing(10 * 2)});
  `};
  span {
    line-height: 100%;
  }
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;
