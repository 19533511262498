import React from 'react';
import { EVENT_ITEM_DATA_INTER, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const GroupMessage: React.FC<{
  data: EVENT_ITEM_DATA_INTER;
}> = ({ data }) => {
  const { messages } = data;

  return (
    <>
      <EllipsisContainerElem></EllipsisContainerElem>
      <TextElem
        type="medium"
        color="textPrimary"
        oneLine
        tid="EVENT.LIST.MESSAGE_COUNTER"
        tvalue={{ count: messages.length }}
        style={{ paddingLeft: `${Spacing(5)}` }}
      />
    </>
  );
};
