import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { RouteComponentProps } from 'react-router';
import { PersonListContainer } from '../../epic/person-list';
import { SessionHeaderContainer } from '../../epic/session-header';
import { SessionItemDataContainer } from '../../epic/session-item-data';
import { SessionDataContainer } from '../../epic/session-data';

interface SessionPersonPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionPersonPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;

  return (
    <PageElem>
      <LayoutAppElem tab>
        <MenuAppContainer />
        <LayoutElem size="small">
          <SessionDataContainer sessionId={sessionId} />
          <SessionHeaderContainer size="small" sessionId={sessionId} />
          <LayoutSectionElem spacing={8}>
            <PersonListContainer sessionId={sessionId} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
