import React, { useEffect, useState } from 'react';
import { i18n } from '../../lib/lang';
import { PROPS_TYPE } from './constant';
import { SelectAsyncElem } from '../../common/select-async';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { getIn } from 'formik';

export const Container: React.FC<PROPS_TYPE> = ({
  action,
  convert,
  formik,
  param,
  value,
  isMulti,
  name,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<
    SELECT_OPTION_ITEM_DATA[] | null
  >(isMulti ? [] : null);
  const [initialOptions, setInitialOptions] = useState<
    SELECT_OPTION_ITEM_DATA[]
  >([]);

  // Функция для загрузки опций
  const loadOptions = async (inputValue: string) => {
    return convert(await action(param, inputValue));
  };

  useEffect(() => {
    const loadInitialOptions = async () => {
      const initialData = await action(param, '');
      const convertedData = convert(initialData);
      setInitialOptions(convertedData);
    };

    loadInitialOptions();
  }, [action, param, convert]);

  useEffect(() => {
    if (value?.length) {
      const loadSelectedOption = async () => {
        if (getIn(formik.values, name)) {
          const selectedId = getIn(formik.values, name);
          const result = convert(await action(param, selectedId));
          setSelectedOption(result || null);
        }
      };

      loadSelectedOption();
    } else {
      setSelectedOption(null);
    }
  }, [getIn(formik.values, name)]);

  const onChange = (name: string, event: any) => {
    if (isMulti) {
      formik.setFieldValue(
        name,
        Array.isArray(event)
          ? event.map((item: SELECT_OPTION_ITEM_DATA) => item.value)
          : [event.value],
      );
      setSelectedOption(Array.isArray(event) ? event : []);
    } else {
      formik.setFieldValue(name, event.value);
      setSelectedOption(event);
    }
  };

  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  const customComponents = isMulti
    ? { MultiValueContainer: multiValueContainer }
    : {};

  return (
    <SelectAsyncElem
      cacheOptions
      defaultOptions={initialOptions}
      loadOptions={loadOptions}
      loadingMessage={() => i18n.t('COMMON.SELECT.LOADING')}
      dynamic
      onChange={onChange}
      value={selectedOption}
      closeMenuOnSelect={!isMulti}
      customComponents={customComponents}
      isMulti={isMulti}
      name={name}
      {...props}
    />
  );
};
