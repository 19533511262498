import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { ButtonElem } from '../../../common/button';
import { createReservation } from '../action';
import { useMutation } from 'react-query';
import { decryptSecretKey } from '../../../lib/util/crypto';
import { GridElem } from '../../../common/grid';

const CheckoutForm: React.FC<{ reservationId: string }> = ({
  reservationId,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSuccess = async (d: any) => {
    const secretKey = decryptSecretKey(d);

    if (stripe && elements) {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: `${secretKey}`,
        confirmParams: {
          return_url: 'https://example.com/order/123/complete',
        },
        redirect: 'if_required',
      });

      if (error) {
        setErrorMessage(error.message || 'An error occurred');
      } else {
        // Handle success (redirect or update UI)
      }
    }
  };

  const action = useMutation(() => createReservation(reservationId), {
    onSuccess,
  });

  const onClick = async (event: any) => {
    event.preventDefault();

    if (!elements) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message || 'An error occurred');
      return;
    }

    action.mutate();
  };

  return (
    <form>
      <GridElem spacing={5}>
        <PaymentElement />
        <ButtonElem
          type="button"
          disabled={!stripe || !elements}
          tid="Оплатити"
          onClick={onClick}
        />
      </GridElem>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
