import React from 'react';
import styled from 'styled-components';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import { IonSkeletonText } from '@ionic/react';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { FlexElem } from '../../common/flex';

export const Skeleton: React.FC<{}> = ({}) => {
  return (
    <>
      <FlexElem style={{ justifyContent: 'space-between', width: '100%' }}>
        <SpeakerInfo>
          <SkeletonText
            style={{
              borderRadius: '100%',
              height: '32px',
              width: '32px',
              minWidth: '32px',
            }}
            animated
          />
          <SpeakerTextInfo spacing={1}>
            <SkeletonText
              style={{
                height: '13px',
                width: '70px',
              }}
              animated
            />
            <SkeletonText
              style={{
                height: '16px',
                width: '130px',
              }}
              animated
            />
          </SpeakerTextInfo>
        </SpeakerInfo>
        <FlexElem style={{ width: 'fit-content' }}>
          <SkeletonText
            style={{
              height: '16px',
              width: '130px',
            }}
            animated
          />
          <SkeletonText
            style={{
              height: '16px',
              width: '130px',
            }}
            animated
          />
        </FlexElem>
      </FlexElem>
      <SkeletonText style={{ height: '100%', width: '100%' }} />
    </>
  );
};

export const SpeakerInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
  @media screen and (width<600px) {
    display: none;
  }
`;

const SkeletonText = styled(IonSkeletonText)`
  height: 22px;
  margin: 0;
  width: 80px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
`;

export const SpeakerTextInfo = styled(GridElem)``;
