import { components } from 'react-select';
import { GridElem } from '../../../../common/grid';
import { TextElem } from '../../../../common/text';
import { FlexElem } from '../../../../common/flex';

export const Option = (props: any) => {
  const { title, content } = props.data;

  return (
    <components.Option {...props}>
      <GridElem spacing={1}>
        <FlexElem spacing={1}>
          <TextElem size="small" color="textSecondary">
            {title}
          </TextElem>
        </FlexElem>
        <TextElem size="verySmall" color="textPrimary">
          {content}
        </TextElem>
      </GridElem>
    </components.Option>
  );
};
