import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';

import { TextElem } from '../../common/text';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

import { ReactComponent as AddIconLarge } from '../../asset/svg/common/add.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';

import addIcon from '../../asset/svg/button/plus.svg';
import generateIcon from '../../asset/svg/button/generate.svg';
import { FileItemUploadContainer } from '../file-item-upload';
import { SelectElem } from '../../common/select';
import { PERSON_ROLE_OPTION_LIST } from '../../data/person/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  formVisible: boolean;
  setFormVisible: Function;
  onSuccessUpload: Function;
  setValue: Function;
  setFieldValue: Function;
  generateRandomData: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  formVisible,
  setFormVisible,
  onSuccessUpload,
  setValue,
  setFieldValue,
  generateRandomData,
}) => {
  const closeModal = () => {
    formik.resetForm();

    setFormVisible(false);
  };
  return (
    <>
      {formVisible ? (
        <FormElem onSubmit={formik.handleSubmit}>
          {isLoading && <LoaderElem />}
          <ContentContainerElem>
            <Container spacing={4}>
              <TitleContainer>
                <TextElem
                  tid="PERSON.CREATE.TITLE"
                  type="semi-bold"
                  size="main"
                />
                <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
              </TitleContainer>
              <FormContainer spacing={5}>
                <GridElem spacing={3}>
                  <FieldTextElem
                    name={FORM_VALUE_ENUM.NAME}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="PERSON.FORM.NAME"
                    value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                    error={isFieldError(FORM_VALUE_ENUM.NAME)}
                  />
                  <DoubleContainerElem>
                    <SelectElem
                      name={FORM_VALUE_ENUM.ROLE}
                      onChange={setFieldValue}
                      options={PERSON_ROLE_OPTION_LIST}
                      title="USER.CREATE.FORM.ROLE"
                      errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
                      error={isFieldError(FORM_VALUE_ENUM.ROLE)}
                      value={setValue(
                        PERSON_ROLE_OPTION_LIST,
                        FORM_VALUE_ENUM.ROLE,
                      )}
                    />
                    <FieldTextElem
                      name={FORM_VALUE_ENUM.PHONE}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="phone"
                      title="PERSON.FORM.PHONE"
                      value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                      errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                      error={isFieldError(FORM_VALUE_ENUM.PHONE)}
                    />
                  </DoubleContainerElem>

                  <DoubleContainerElem>
                    <FieldTextElem
                      name={FORM_VALUE_ENUM.COUNTRY}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      title="PERSON.FORM.COUNTRY"
                      value={getFieldValue(FORM_VALUE_ENUM.COUNTRY)}
                      errorMessage={getFieldError(FORM_VALUE_ENUM.COUNTRY)}
                      error={isFieldError(FORM_VALUE_ENUM.COUNTRY)}
                    />
                    <FieldTextElem
                      name={FORM_VALUE_ENUM.CITY}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      title="PERSON.FORM.CITY"
                      value={getFieldValue(FORM_VALUE_ENUM.CITY)}
                      errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
                      error={isFieldError(FORM_VALUE_ENUM.CITY)}
                    />
                  </DoubleContainerElem>
                </GridElem>
                <GridElem spacing={3}>
                  <TextElem
                    tid="PERSON.FORM.PHOTO"
                    color="textPrimary"
                    style={{ height: '14px' }}
                  />
                  <FileItemUploadContainer
                    onSuccess={onSuccessUpload}
                    name={FORM_VALUE_ENUM.FILE}
                    type="image"
                    description={false}
                  />
                </GridElem>
              </FormContainer>

              <DoubleContainerElem>
                <ButtonElem
                  type="submit"
                  tid="PERSON.CREATE.BUTTON.CREATE"
                  fill="solid"
                  disabled={isSubmitDisabled()}
                  iconRight={addIcon}
                />
                <ButtonElem
                  tid="PERSON.CREATE.BUTTON.GENERATE"
                  fill="solid"
                  type="button"
                  onClick={generateRandomData}
                  iconRight={generateIcon}
                  color="backgroundThird"
                />
              </DoubleContainerElem>

              {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            </Container>
          </ContentContainerElem>
        </FormElem>
      ) : (
        <AddContainer onClick={() => setFormVisible(true)}>
          <TextElem tid="PERSON.CREATE.ADD" type="semi-bold" size="main" />
          <AddIconLarge />
        </AddContainer>
      )}
    </>
  );
};

const FormContainer = styled(GridElem)`
  grid-template-columns: auto 130px;

  @media screen and (width<700px) {
    grid-template-columns: 1fr;
    gap: ${Spacing(3)};
  }

  .file-upload-wrapper {
    height: 130px !important;
    min-height: 130px !important;
  }
`;

const Container = styled(GridElem)`
  @media screen and (width>900px) {
    gap: ${Spacing(5)};
  }
`;

// const FieldContainer = styled(GridElem)`
//   @media screen and (width>600px) {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   @media screen and (width>900px) {
//     grid-template-columns: repeat(4, 1fr);
//   }
// `;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddContainer = styled(TitleContainer)`
  padding: ${Spacing(4)} ${Spacing(5)};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  transition: all 0.2s;
  :hover {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
`;
