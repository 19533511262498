import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { RouteComponentProps } from 'react-router';

import { WebinarItemDataContainer } from '../../epic/webinar-item-data';
import { WebinarItemUpdateGeneralContainer } from '../../epic/webinar-item-update-general';
import { WebinarHeaderContainer } from '../../epic/webinar-header';
import { WebinarItemUpdateMediaContainer } from '../../epic/webinar-item-update-media';
import { WebinarItemUpdatePreviewContainer } from '../../epic/webinar-item-update-preview';
import { WebinarItemUpdateActionContainer } from '../../epic/webinar-item-update-action';
import { WebinarItemUpdateIntegrationContainer } from '../../epic/webinar-item-update-integration';
interface WebinarSettingsPageProps
  extends RouteComponentProps<{
    webinarId: string;
  }> {}

export const Page: React.FC<WebinarSettingsPageProps> = ({ match }) => {
  const webinarId = match.params.webinarId;

  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />
        <LayoutElem>
          <WebinarItemDataContainer webinarId={webinarId} />
          <WebinarHeaderContainer webinarId={webinarId} />
          <LayoutSectionElem spacing={5}>
            <WebinarItemUpdateGeneralContainer webinarId={webinarId} />
            <WebinarItemUpdateMediaContainer webinarId={webinarId} />
            <WebinarItemUpdatePreviewContainer webinarId={webinarId} />
            <WebinarItemUpdateIntegrationContainer webinarId={webinarId} />
            <WebinarItemUpdateActionContainer webinarId={webinarId} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
