import { FORM_VALUE_ENUM, FORM_VALUE_RAW } from './constant';

export const convert = (data: FORM_VALUE_RAW) => {
  return {
    ...data,
    [FORM_VALUE_ENUM.MESSAGE]: data[FORM_VALUE_ENUM.MESSAGE]?.trim(),
    [FORM_VALUE_ENUM.BUTTON_TEXT]: data[FORM_VALUE_ENUM.BUTTON_TEXT]?.trim(),
    [FORM_VALUE_ENUM.PERSON]: data[FORM_VALUE_ENUM.PERSON],
  };
};
