import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  WEBINAR_ITEM_DATA_RAW_INTER,
  WEBINAR_ITEM_DATA_INTER,
  WEBINAR_ITEM_LIST_DATA_INTER,
} from './constant';

export const convertWebinar = (
  webinar: WEBINAR_ITEM_DATA_RAW_INTER,
): WEBINAR_ITEM_DATA_INTER => {
  return {
    ...webinar,
  };
};

export const convertWebinarList = (
  webinarList: WEBINAR_ITEM_DATA_RAW_INTER[],
): WEBINAR_ITEM_LIST_DATA_INTER => {
  return {
    list: webinarList?.map((webinar: WEBINAR_ITEM_DATA_RAW_INTER) => {
      return convertWebinar(webinar);
    }),
    isEmpty: !webinarList?.length,
  };
};

export const convertWebinarListToSelect = (
  data: WEBINAR_ITEM_DATA_RAW_INTER[],
) => {
  const newList: SELECT_OPTION_ITEM_DATA[] = [];
  data.map((item: WEBINAR_ITEM_DATA_RAW_INTER) => {
    newList.push({
      value: item.id,

      label: item.name,
    });
  });

  return newList;
};
