import React from 'react';

import { DoubleContainerElem } from '../../common/double-container';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import { GridElem } from '../../common/grid';
import { IonSkeletonText } from '@ionic/react';
import { FlexElem } from '../../common/flex';

import {
  Center,
  DayContainer,
  MediaContent,
  SpeakerInfo,
  SpeakerTextInfo,
} from './component';

export const Skeleton: React.FC<{}> = ({}) => {
  return (
    <>
      <Center spacing={4} style={{ maxWidth: '400px', margin: 'auto' }}>
        <GridElem spacing={8}>
          <MediaContent style={{ gridTemplateColumns: '1fr 1fr' }}>
            <SpeakerInfo>
              <IonSkeletonText
                style={{
                  borderRadius: '100%',
                  height: '32px',
                  width: '32px',
                  minWidth: '32px',
                }}
                animated
              />
              <SpeakerTextInfo spacing={1}>
                <IonSkeletonText
                  style={{
                    height: '13px',
                    width: '100px',
                  }}
                  animated
                />
                <IonSkeletonText
                  style={{
                    height: '16px',
                    width: '120px',
                  }}
                  animated
                />
              </SpeakerTextInfo>
            </SpeakerInfo>
            <SkeletonFieldElem title={false} />
          </MediaContent>
        </GridElem>

        <IonSkeletonText
          style={{
            height: '28px',
            width: '70%',
          }}
          animated
        />
      </Center>
      <Center>
        <GridElem spacing={7} style={{ maxWidth: '290px' }}>
          <Center spacing={3}>
            <DayContainer spacing={1}>
              <IonSkeletonText
                style={{
                  height: '15px',
                  width: '70%',
                }}
                animated
              />
            </DayContainer>
            <Center>
              <FlexElem spacing={1} style={{ justifyContent: 'center' }}>
                <IonSkeletonText
                  style={{
                    height: '18px',
                    width: '70%',
                  }}
                  animated
                />
              </FlexElem>
            </Center>
          </Center>

          <GridElem spacing={4}>
            <SkeletonFieldElem />
            <SkeletonFieldElem />
            <SkeletonFieldElem title={false} />

            <DoubleContainerElem style={{ gridTemplateColumns: '1fr 1fr' }}>
              <SkeletonFieldElem title={false} style={{ height: '59px' }} />
              <SkeletonFieldElem title={false} style={{ height: '59px' }} />
            </DoubleContainerElem>
          </GridElem>
        </GridElem>
      </Center>
    </>
  );
};
