import React from 'react';
import styled, { css } from 'styled-components';
import { SelectElem } from '../../../common/select';
import { COLOR_ENUM } from '../../../theme/color';
import { MODULE_NAME, TYPE_OPTION_LIST } from '../constant';

export const TypeSelect: React.FC<{
  setType: Function;
  type: any;
}> = ({ setType, type }) => {
  const onChange = (name: string, values: any) => {
    setType(values);
  };

  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  return (
    <Container className="type">
      <SelectCustom
        name={MODULE_NAME}
        onChange={onChange}
        options={TYPE_OPTION_LIST}
        noAlertContainer
        hideSelectedOptions={false}
        // value={value}
        isMulti
        placeholder="Type"
        closeMenuOnSelect={false}
        customComponents={{ MultiValueContainer: multiValueContainer }}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const SelectCustom = styled(SelectElem)`
  .Select__value-container--is-multi {
    display: flex;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};

    width: 119px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* .Select__value-container--has-value {
    display: block;
  } */

  .Select__menu {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .Select__control {
    margin: auto;
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;
