import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { AlertActionElem } from '../../common/alert-action';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';

import { DoubleContainerElem } from '../../common/double-container';
import { LoaderElem } from '../../common/loader';
import { FormElem } from '../../common/form';
import { ButtonElem } from '../../common/button';
import { Skeleton } from './skeleton';
import { ContentContainerElem } from '../../common/content-container';
import { WEBINAR_UPDATE_FORM_VALUE_ENUM } from '../webinar-item-update-general';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isLoadingPreFetch?: boolean;
  isSuccessPreFetch?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  isLoadingPreFetch,
  isSuccessPreFetch,
  isSuccess,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isLoadingPreFetch && <Skeleton />}
      {isSuccessPreFetch && (
        <ContentContainerElem>
          <GridElem spacing={5}>
            <TextElem
              tid="WEBINAR.UPDATE.MODER_MESSAGE.TITLE"
              type="semi-bold"
              size="main"
            />

            <FormElem onSubmit={formik.handleSubmit}>
              <GridElem spacing={4}>
                <FieldTextElem
                  name={WEBINAR_UPDATE_FORM_VALUE_ENUM.MODER_MESSAGE}
                  onChange={formik.handleChange}
                  // onBlur={formik.handleSubmit}
                  title="WEBINAR.UPDATE.MODER_MESSAGE.FORM.MODER_MESSAGE"
                  value={getFieldValue(
                    WEBINAR_UPDATE_FORM_VALUE_ENUM.MODER_MESSAGE,
                  )}
                  errorMessage={getFieldError(
                    WEBINAR_UPDATE_FORM_VALUE_ENUM.MODER_MESSAGE,
                  )}
                  error={isFieldError(
                    WEBINAR_UPDATE_FORM_VALUE_ENUM.MODER_MESSAGE,
                  )}
                />
                <DoubleContainerElem>
                  <ButtonElem
                    type="submit"
                    tid="WEBINAR.UPDATE.MODER_MESSAGE.BUTTON.CREATE"
                    disabled={isSubmitDisabled()}
                    color="success"
                  />
                </DoubleContainerElem>
              </GridElem>
              {isSuccess && <AlertActionElem type="success" tid="Success" />}

              {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            </FormElem>
          </GridElem>
        </ContentContainerElem>
      )}
    </>
  );
};
