import React from 'react';
import { IonModal } from '@ionic/react';
import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';

import { PROPS_TYPE } from './constant';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { GridElem } from '../grid';
import { TextElem } from '../text';

export const Elem: React.FC<PROPS_TYPE> = ({
  open,
  onClose,
  children,
  closeAction,
  className,
  titleTid,
  closeIcon,
}) => {
  const onCloseAction = () => {
    onClose && onClose();
    closeAction && closeAction();
  };
  const handleClose = () => onCloseAction();

  return (
    <Modal
      isOpen={open}
      // initialBreakpoint={1}
      // breakpoints={[0, 1]}
      // handleBehavior="cycle"
      onDidDismiss={handleClose}
      trigger="open-modal"
      className={className}
    >
      <GridElem spacing={5} style={{ paddingBottom: Spacing(6) }}>
        {!!titleTid && !closeIcon && (
          <TitileContainer style={{ gridTemplateColumns: 'auto 16px' }}>
            {titleTid ? (
              <TextElem
                tid={titleTid}
                size="medium"
                type="semi-bold"
                className="modalTitle"
              />
            ) : (
              <div />
            )}

            {closeIcon && <CloseIconStyled onClick={handleClose} />}
          </TitileContainer>
        )}

        <Content id="content-container" style={{ height: '100%' }}>
          {children}
        </Content>
      </GridElem>
    </Modal>
  );
};

const TitileContainer = styled(GridElem)`
  padding: ${Spacing(6)} ${Spacing(4)};

  padding-bottom: 0;
`;

const Content = styled.div`
  padding: 0 ${Spacing(4)};
`;

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

export const Modal = styled(IonModal)<{ minHeight?: number }>`
  pointer-events: none;
  --height: auto;
  ${({ minHeight }) => css`
    min-height: ${minHeight}px;
  `}
  --backdrop-opacity:0;
  --box-shadow: none;

  background: rgba(20, 18, 26, 0.8);

  ::part(content) {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
    max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
    border-radius: 16px;
    position: absolute;
    top: 100px;
    width: 450px;

    @media screen and (width<700px) {
      width: 100%;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      height: auto;
    }
  }
  & > div > div {
    margin: auto;
    max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
    /* padding: ${Spacing(6)} ${Spacing(4)}; */
  }
  &.modal-handle::before {
    display: none;
    content: none;
  }
`;
