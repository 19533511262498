import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { ExpenditureItemCreateContainer } from '../../epic/expenditure-item-create';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutElem oneColumn>
          <ExpenditureItemCreateContainer />
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
