import { useEffect } from 'react';
import { useSocket } from '../../data/socket/provider';
import {
  EVENT_ITEM_DATA_RAW_INTER,
  QUESTION_TYPE,
  REACTION_RAW_TYPE,
  RESERVATION_CREATED_TYPE,
} from '../../data/event/constant';
import { MESSAGE_ITEM_DATA_RAW_INTER } from '../../data/message/constant';
import { BASE_URL } from '../../lib/http/constant';
import { useQueryClient } from 'react-query';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';

interface useRoomSocketProps {
  sessionToken: string;
  sessionId: string;
  setChatMessage: React.Dispatch<
    React.SetStateAction<MESSAGE_ITEM_DATA_RAW_INTER[]>
  >;
  setSessionStart: React.Dispatch<React.SetStateAction<boolean>>;
  setEventStarted: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionEnd: React.Dispatch<React.SetStateAction<boolean>>;
  setEvent: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW_INTER | undefined>
  >;
  setBanner: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW_INTER | undefined>
  >;
  setButton: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW_INTER | undefined>
  >;
  setReactions: React.Dispatch<
    React.SetStateAction<REACTION_RAW_TYPE | undefined>
  >;

  setReservationCreated: React.Dispatch<
    React.SetStateAction<RESERVATION_CREATED_TYPE | undefined>
  >;
  setQuestion: React.Dispatch<React.SetStateAction<QUESTION_TYPE | undefined>>;
  convertMessageList: (
    messages: MESSAGE_ITEM_DATA_RAW_INTER[],
  ) => MESSAGE_ITEM_DATA_RAW_INTER[];
  convertMessage: (
    message: MESSAGE_ITEM_DATA_RAW_INTER,
  ) => MESSAGE_ITEM_DATA_RAW_INTER;
}

const useRoomSocket = ({
  sessionToken,
  sessionId,
  setChatMessage,
  setSessionStart,
  setEventStarted,
  setSessionEnd,
  setEvent,
  convertMessageList,
  convertMessage,
  setBanner,
  setButton,
  setReactions,
  setReservationCreated,
  setQuestion,
}: useRoomSocketProps) => {
  const {
    socket,
    initializeSocket,
    sendMessage,
    sendReaction,
    reservationButtonClick,
    reservationCreated,
  } = useSocket();

  const query = useQueryClient();

  useEffect(() => {
    if (sessionToken) {
      const cleanUpSocket = initializeSocket(BASE_URL as string, {
        extraHeaders: {
          session_token: sessionToken,
        },
      });
      const handleBeforeUnload = () => {
        if (socket) {
          socket.emit('leaveSession', `${sessionId}`);
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        if (socket) {
          socket.emit('leaveSession', `${sessionId}`);
        }
        cleanUpSocket;
      };
    }
  }, [sessionToken]);

  useEffect(() => {
    if (socket) {
      socket.emit('joinSession', `${sessionId}`);

      socket.on(
        'sessionMessages',
        (messages: MESSAGE_ITEM_DATA_RAW_INTER[]) => {
          console.log('sessionMessages');
          setChatMessage(convertMessageList(messages));
        },
      );

      socket.on('chat', (message: MESSAGE_ITEM_DATA_RAW_INTER) => {
        console.log('chat', message);
        setChatMessage((previousMessages) => [
          ...previousMessages,
          convertMessage(message),
        ]);
      });

      socket.on('reactionUpdate', (reactionData: REACTION_RAW_TYPE) => {
        console.log(reactionData, 'getReactions');

        setReactions(reactionData);
      });

      socket.on('paymentSuccess', () => {
        console.log('sessionStart');

        query.invalidateQueries(VIEWER_ITEM_DATA_MODULE_NAME);
      });

      socket.on('sessionStart', () => {
        console.log('sessionStart');
        setSessionStart(true);
      });

      socket.on('eventStart', () => {
        console.log('eventStart');
        setEventStarted(true);
      });

      socket.on('sessionEnd', () => {
        setSessionEnd(true);
      });

      socket.on('showReservation', (event: EVENT_ITEM_DATA_RAW_INTER) => {
        console.log('showReservation');
        setEvent(event);
      });

      socket.on('showButton', (event: EVENT_ITEM_DATA_RAW_INTER) => {
        console.log('showButton', event);

        setButton(event);
      });

      socket.on('showBanner', (event: EVENT_ITEM_DATA_RAW_INTER) => {
        console.log('showBanner', event);
        setBanner(event);
      });

      socket.on('showQuestion', (data: QUESTION_TYPE) => {
        console.log('showQuestion', data);
        setQuestion(data);
      });

      socket.on('showReservationCreated', (data: RESERVATION_CREATED_TYPE) => {
        console.log('showReservationCreated', data);
        setReservationCreated(data);
        setTimeout(() => {
          if (data.name === reservationCreated.name)
            setReservationCreated(undefined);
        }, 3000);
      });

      socket.on('hideButton', () => {
        console.log('hideButton');
        setButton(undefined);
      });

      socket.on('hideBanner', () => {
        console.log('hideBanner');
        setBanner(undefined);
      });

      socket.on('closeReservation', () => {
        setEvent(undefined);
      });

      // return () => {
      //   socket.off('sessionMessages');
      //   socket.off('chat');
      //   socket.off('sessionStart');
      //   socket.off('sessionEnd');
      //   socket.off('showReservation');
      //   socket.off('closeReservation');
      // };
    }
  }, [socket]);

  return { sendMessage, reservationButtonClick, sendReaction };
};

export default useRoomSocket;
