import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';

import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';

import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import {
  EXPENDITURE_BILL_OPTION_LIST,
  EXPENDITURE_CATEGORY_OPTION_LIST,
  EXPENDITURE_DEPARTMENT_OPTION_LIST,
  EXPENDITURE_VALUTE_OPTION_LIST,
} from '../../data/expenditure/constant';
import { SelectElem } from '../../common/select';
import { getAllUserList, getUserList } from '../../data/user/action';
import { convertUserListToSelect } from '../../data/user/convert';
import { SelectPreloadContainer } from '../select-preload';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { TextElem } from '../../common/text';
import { FileItemUploadContainer } from '../file-item-upload';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  isSuccess?: boolean;
  onSuccessUpload: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
  isSuccess,
  onSuccessUpload,
}) => {
  return (
    <GridElem spacing={6}>
      <TextElem
        tid="EXPENDITURE.CREATE.TITLE"
        style={{ textAlign: 'center' }}
        size="heading"
        type="semi-bold"
      />
      <FormElem onSubmit={formik.handleSubmit}>
        {isLoading && <LoaderElem />}
        <ContentContainerElem>
          <GridElem spacing={3}>
            <FieldDataTimeInputElem
              name={FORM_VALUE_ENUM.DEADLINE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DEADLINE.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DEADLINE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DEADLINE)}
              error={isFieldError(FORM_VALUE_ENUM.DEADLINE)}
              format="YYYY-MM-DD"
              showTime={false}
            />

            <DoubleContainerElem>
              <FieldTextElem
                name={FORM_VALUE_ENUM.AMOUNT}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="EXPENDITURE.FORM.AMOUNT.TITLE"
                placeholder="EXPENDITURE.FORM.AMOUNT.PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
                error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
                type="number"
              />

              <SelectElem
                name={FORM_VALUE_ENUM.VALUTE}
                onChange={setFieldValue}
                options={EXPENDITURE_VALUTE_OPTION_LIST}
                title="EXPENDITURE.FORM.VALUTE.TITLE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.VALUTE)}
                error={isFieldError(FORM_VALUE_ENUM.VALUTE)}
                value={setValue(
                  EXPENDITURE_VALUTE_OPTION_LIST,
                  FORM_VALUE_ENUM.VALUTE,
                )}
              />
            </DoubleContainerElem>
            <DoubleContainerElem>
              <SelectElem
                name={FORM_VALUE_ENUM.BILL}
                onChange={setFieldValue}
                options={EXPENDITURE_BILL_OPTION_LIST}
                title="EXPENDITURE.FORM.BILL.TITLE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.BILL)}
                error={isFieldError(FORM_VALUE_ENUM.BILL)}
                value={setValue(
                  EXPENDITURE_BILL_OPTION_LIST,
                  FORM_VALUE_ENUM.BILL,
                )}
              />

              <SelectElem
                name={FORM_VALUE_ENUM.CATEGORY}
                onChange={setFieldValue}
                options={EXPENDITURE_CATEGORY_OPTION_LIST}
                title="EXPENDITURE.FORM.CATEGORY.TITLE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
                error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
                value={setValue(
                  EXPENDITURE_CATEGORY_OPTION_LIST,
                  FORM_VALUE_ENUM.CATEGORY,
                )}
              />
            </DoubleContainerElem>
            <DoubleContainerElem>
              <SelectElem
                name={FORM_VALUE_ENUM.DEPARTMENT}
                onChange={setFieldValue}
                options={EXPENDITURE_DEPARTMENT_OPTION_LIST}
                title="EXPENDITURE.FORM.DEPARTMENT.TITLE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
                error={isFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
                value={setValue(
                  EXPENDITURE_DEPARTMENT_OPTION_LIST,
                  FORM_VALUE_ENUM.DEPARTMENT,
                )}
              />

              <SelectPreloadContainer
                name={FORM_VALUE_ENUM.RESPONSIBLE}
                action={getAllUserList}
                convert={convertUserListToSelect}
                formik={formik}
                title="EXPENDITURE.FORM.RESPONSIBLE.TITLE"
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
                error={isFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
                value={getFieldValue(FORM_VALUE_ENUM.RESPONSIBLE)}
              />
            </DoubleContainerElem>
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.REQUISITES}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.REQUISITES.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.REQUISITES)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUISITES)}
              error={isFieldError(FORM_VALUE_ENUM.REQUISITES)}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DESCRIPTION.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            />
          </GridElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.COMMENT}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="EXPENDITURE.FORM.COMMENT.TITLE"
            value={getFieldValue(FORM_VALUE_ENUM.COMMENT)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.COMMENT)}
            error={isFieldError(FORM_VALUE_ENUM.COMMENT)}
          />
          <DoubleContainerElem>
            <GridElem spacing={2}>
              <TextElem
                tid="EXPENDITURE.FORM.COMMENT_FILE.TITLE"
                color="textPrimary"
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                name={FORM_VALUE_ENUM.COMMENT_FILE}
                type="file"
              />
            </GridElem>
          </DoubleContainerElem>

          <DoubleContainerElem>
            <ButtonElem
              type="submit"
              tid="EXPENDITURE.FORM.CREATE"
              fill="solid"
              color="success"
              disabled={isSubmitDisabled()}
            />
          </DoubleContainerElem>

          {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
          {isSuccess && <AlertActionElem type="success" tid="Success" />}
          {isLoading && <LoaderElem />}
        </ContentContainerElem>
      </FormElem>
    </GridElem>
  );
};
