import { convertDateToList } from '../../lib/lang/service';
import {
  FILE_ITEM_DATA_RAW_INTER,
  FILE_ITEM_DATA_INTER,
  FILE_ITEM_LIST_DATA_RAW_INTER,
  FILE_ITEM_LIST_DATA_INTER,
} from './constant';

export const convertFile = (
  file: FILE_ITEM_DATA_RAW_INTER,
): FILE_ITEM_DATA_INTER => {
  return {
    ...file,
    createDate: convertDateToList(file.createDate),
  };
};

export const convertFileList = (
  fileList: FILE_ITEM_DATA_RAW_INTER[],
): FILE_ITEM_LIST_DATA_INTER => {
  return {
    list: fileList?.map((file: FILE_ITEM_DATA_RAW_INTER) => {
      return convertFile(file);
    }),
    isEmpty: !fileList?.length,
  };
};
