import styled, { css } from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';

import { ReactComponent as CalendarIcon } from '../../../asset/svg/common/calendar.svg';
import ArrowIcon from '../../../asset/svg/common/arrow.svg';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../../theme/size';
import { CALENDAR } from '../../viewer-list/constant';
import { TimePicker } from 'antd';
import { ReactComponent as CloseIcon } from '../../../asset/svg/common/close.svg';
import dayjs, { Dayjs } from 'dayjs';

export const TimeRange: React.FC<{
  setRange: Function;
}> = ({ setRange }) => {
  const [value, setValue] = useState<[Dayjs | null, Dayjs | null] | null>(null);

  const handleChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string],
  ) => {
    setValue(dates);
    setRange(dateStrings);
  };

  return (
    <Container id="container" className="timeRange">
      <Field
        // disableDayPicker
        format="HH:mm:ss"
        value={value}
        onChange={handleChange}
        onCalendarChange={handleChange}
        needConfirm={false}
        suffixIcon={<CalendarIcon />}
        // cellRender={{ info: { originNode: <ArrowIcon /> } }}
        // onBlur={handleTimeRangeChange}
        // popupClassName={'calendar-popup'}
        // popupStyle={antCfg}
        // style={S.Radio}
        allowClear={{ clearIcon: <CloseIcon /> }}
        //@ts-ignore
        getPopupContainer={() => document.getElementById('container')}
      />
    </Container>
  );
};

const Container = styled.div`
  .ant-picker-range-arrow {
    display: none !important;
  }
  .ant-picker-panel-container {
    background: transparent;
  }

  .ant-picker {
    padding: 0 ${Spacing(4)};
  }

  .ant-picker-panel {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.INPUT_ACTIVE]} !important;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
  }

  .ant-picker-footer {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.INPUT_ACTIVE]} !important;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
  }
  .ant-picker-panel-layout {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
    overflow: auto;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
  }
  .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
  }

  .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
  }
`;

const Field = styled(TimePicker.RangePicker)`
  height: 46px;
  box-sizing: border-box;

  .calendar-popup.ant-picker-range-arrow {
    display: none;
  }
  .calendar-popup.ant-picker-panel {
  }
  .ant-picker-range-separator {
    svg {
      path {
        d: path('M20 12H4M4 12L8.75676 6M4 12L8.75676 18');
      }
    }
    position: relative;
    ::after {
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 18px;
      background: url(${ArrowIcon});
      top: 1px;
      left: 5px;
    }
  }

  cursor: pointer;
  :focus-visible {
    outline: none;
  }
  position: relative;
  .ant-picker-active-bar {
    display: none;
  }
  box-shadow: none !important;
  .ant-picker-input > input {
    text-align: center !important;

    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
    }
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]} !important;
  }

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
  }
  line-height: 1em;
  text-align: start;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]} !important;

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};

    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }

  &:focus-within {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]} !important;
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  }

  background: ${({ theme }) =>
    theme[COLOR_ENUM.DEFAULT] === '#1B1924'
      ? theme[COLOR_ENUM.INPUT_BACKGROUND]
      : theme[COLOR_ENUM.INPUT]};

  padding: 0 ${Spacing(4)};

  .ant-picker-clear {
    inset-inline-end: 16px;
  }

  :focus-within {
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
  }

  :hover {
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  background: ${({ theme }) =>
    theme[COLOR_ENUM.DEFAULT] === '#1B1924'
      ? theme[COLOR_ENUM.INPUT_BACKGROUND]
      : theme[COLOR_ENUM.INPUT]};
  padding-left: ${Spacing(9)};
`;
