import React from 'react';

import { ACTION_ERROR_INTER } from './constant';

import { Component } from './component';
import {
  number,
  numberPositiveMin,
  required,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQueryClient } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { EXPENDITURE_ITEM_DATA_INTER } from '../../data/expenditure/constant';
import { EXPENDITURE_LIST_MODULE_NAME } from '../expenditure-list';
import {
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from '../expenditure-item-create/constant';
import { convert } from '../expenditure-item-create/convert';

export const Container: React.FC<{
  data: EXPENDITURE_ITEM_DATA_INTER;
  close: any;
}> = ({ data, close }) => {
  const query = useQueryClient();

  const onSuccess = (d: any, values: any) => {
    query.invalidateQueries(EXPENDITURE_LIST_MODULE_NAME);
    formik.resetForm();
    close();
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => fetch(values, data.id),
    {
      onSuccess,
    },
  );

  const config = {
    [FORM_VALUE_ENUM.DESCRIPTION]: [required],
    [FORM_VALUE_ENUM.REQUISITES]: [required],
    [FORM_VALUE_ENUM.DEADLINE]: [required],
    [FORM_VALUE_ENUM.VALUTE]: [required],
    [FORM_VALUE_ENUM.AMOUNT]: [required, number, numberPositiveMin(0)],
    [FORM_VALUE_ENUM.CATEGORY]: [required],
    [FORM_VALUE_ENUM.BILL]: [required],
    [FORM_VALUE_ENUM.DEPARTMENT]: [required],
    [FORM_VALUE_ENUM.RESPONSIBLE]: [required],
    [FORM_VALUE_ENUM.STATUS]: [required],
    [FORM_VALUE_ENUM.COMMENT_FILE]: [],
    [FORM_VALUE_ENUM.CONFIRM_FILE]: [],
    [FORM_VALUE_ENUM.COMMENT]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.DESCRIPTION]: data.description,
    [FORM_VALUE_ENUM.REQUISITES]: data.requisites,
    [FORM_VALUE_ENUM.DEADLINE]: data.deadLine,
    [FORM_VALUE_ENUM.VALUTE]: data.valute,
    [FORM_VALUE_ENUM.AMOUNT]: data.amount,
    [FORM_VALUE_ENUM.CATEGORY]: data.category,
    [FORM_VALUE_ENUM.BILL]: data.bill,
    [FORM_VALUE_ENUM.DEPARTMENT]: data.departmant,
    [FORM_VALUE_ENUM.RESPONSIBLE]: data.responsible.id,
    [FORM_VALUE_ENUM.STATUS]: data.status,
    [FORM_VALUE_ENUM.COMMENT_FILE]: data?.commentFile?.id,
    [FORM_VALUE_ENUM.CONFIRM_FILE]: data?.confirmFile?.id,
    [FORM_VALUE_ENUM.COMMENT]: data.comment,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];
  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
  };

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      setValue={setValue}
      close={close}
      isSuccess={isSuccess()}
      onSuccessUpload={onSuccessUpload}
      data={data}
    />
  );
};
