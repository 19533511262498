import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'VIEWER_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const CATEGORY_OPTION_LIST = [
  { value: 'record', label: i18n.t('VIEWER.FILTER.CATEGORY.NEED_RECORD') },
  { value: 'reservation', label: i18n.t('VIEWER.FILTER.CATEGORY.RESERVATION') },
  { value: 'payeed', label: i18n.t('VIEWER.FILTER.CATEGORY.PAYEED') },
];

export const SORT_OPTION_LIST = [
  { value: 'name_desc', label: i18n.t('VIEWER.FILTER.SORT.NAME_DESC') },
  { value: 'name_asc', label: i18n.t('VIEWER.FILTER.SORT.NAME_ASC') },
];
