import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { DATA_INTER } from './constant';
import { FILE_ITEM_DATA_INTER } from '../../data/file/constant';
import { FileItemElem } from '../../data/file/frame/file-item-list.elem ';
import { FileSkeletonElem } from '../../data/file/frame/file-skeleton.elem';
import { FileItemUploadContainer } from '../file-item-upload';
import { FILE_LIST_MODULE_NAME } from '.';
import { useQueryClient } from 'react-query';
import { IonInfiniteScroll, IonList } from '@ionic/react';
import { Spacing } from '../../theme';

export const Component: React.FC<{
  data?: DATA_INTER;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  hasNextPage?: boolean;
}> = ({
  data,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  hasNextPage,
}) => {
  const query = useQueryClient();
  const onSuccess = () => {
    query.invalidateQueries(FILE_LIST_MODULE_NAME);
  };
  return (
    <Container>
      <FileItemUploadContainer onSuccess={onSuccess} resetAfterUpload />

      {isError && <AlertActionElem text={errorMessage} />}

      {data && data?.list && (
        <>
          {data.list.map((item: FILE_ITEM_DATA_INTER, index: number) => (
            <FileItemElem {...item} key={item.id} />
          ))}
        </>
      )}
      {hasNextPage && (
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            await fetchNextPage();
            ev.target.complete();
          }}
          threshold="100px"
          disabled={!hasNextPage}
        >
          <FileSkeletonElem />
        </IonInfiniteScroll>
      )}

      {isFetching && (
        <>
          <FileSkeletonElem />
          <FileSkeletonElem />
          <FileSkeletonElem />
        </>
      )}
    </Container>
  );
};

const Container = styled(IonList)`
  display: grid;
  gap: ${Spacing(3)};
  padding: 0;
  background: transparent;
  @media screen and (width>800px) {
    grid-template-columns: 1fr 1fr;
  }
`;
