import React from 'react';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';

import { AdminHeaderContainer } from '../../epic/admin-header';
import { FileListContainer } from '../../epic/file-list';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />
        <LayoutElem size="default">
          <AdminHeaderContainer />
          <LayoutSectionElem spacing={8}>
            <FileListContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
