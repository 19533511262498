import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { ACTION_ERROR_INTER, CALENDAR, MODULE_NAME } from './constant';
import { Component } from './component';
import { convertEventList } from '../../data/event/convert';
import { EVENT_ITEM_DATA_RAW_INTER } from '../../data/event/constant';
import { action } from './action';

export const Container: React.FC<{
  sessionId: string;
}> = ({ sessionId }) => {
  const [sort, setSort] = useState('name_asc');
  const [type, setType] = useState('');
  const [person, setPerson] = useState('');

  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME, sessionId],
    queryFn: ({ pageParam = 1 }) =>
      action({
        pageParam,
        sort,
        person,
        sessionId,
        type,
      }),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : undefined;
    },
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    return convertEventList(
      preFetch?.data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page];
      }, []) as EVENT_ITEM_DATA_RAW_INTER[],
    );
  }, [preFetch?.data, preFetch?.isFetching]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  useEffect(() => {
    preFetch.refetch();
  }, [person, sort, type]);
  return (
    <Component
      sort={sort}
      setSort={setSort}
      person={person}
      setPerson={setPerson}
      type={type}
      setType={setType}
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      sessionId={sessionId}
      hasNextPage={preFetch.hasNextPage}
    />
  );
};
