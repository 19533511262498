import {
  PERSON_ITEM_LIST_DATA_RAW_INTER,
  PERSON_ITEM_LIST_DATA_INTER,
} from '../../data/person/constant';

export const MODULE_NAME = 'PERSON_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (sessionId: string, params?: string) =>
    `/person/list/${sessionId}?${params}`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export interface ACTION_RESPONSE_INTER
  extends PERSON_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PERSON_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
