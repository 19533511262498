import React from 'react';
import { EVENT_ITEM_DATA_INTER } from '../constant';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const GroupMessageMobile: React.FC<{
  data: EVENT_ITEM_DATA_INTER;
}> = ({ data }) => {
  const { messages, person } = data;

  return (
    <>
      <EllipsisContainerElem></EllipsisContainerElem>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textPrimary"
          oneLine
          tid="EVENT.LIST.MESSAGE_COUNTER"
          tvalue={{ count: messages.length }}
        />
      </EllipsisContainerElem>
    </>
  );
};
