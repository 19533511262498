import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { ExpenditureListContainer } from '../../epic/expenditure-list';
import { TextElem } from '../../common/text';
import styled from 'styled-components';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutCustom oneColumn size="default">
          <TextElem
            tid="EXPENDITURE.HEAD.TITLE"
            type="semi-bold"
            size="heading"
            // style={{ textAlign: 'center' }}
          />
          <ExpenditureListContainer />
        </LayoutCustom>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutCustom = styled(LayoutElem)`
  max-width: 1300px;
`;
