import React, { useEffect } from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { RouteComponentProps } from 'react-router';

import { SessionItemRoomContainer } from '../../epic/session-item-room';
import styled from 'styled-components';
import { Spacing } from '../../theme';

interface SessionItemPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionItemPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;

  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutElemStyled fullHeight style={{ maxWidth: '100%' }}>
          <SessionItemRoomContainer sessionId={sessionId} />
        </LayoutElemStyled>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutElemStyled = styled(LayoutElem)`
  padding: ${Spacing(6)} ${Spacing(3)};

  @media screen and (width> 400px) {
    padding: ${Spacing(6)} ${Spacing(5)};
  }

  @media screen and (width> 1200px) {
    padding: ${Spacing(6)} ${Spacing(6)};
  }

  @media screen and (width<1000px) {
    /* height: auto; */
  }
`;
