import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ pageParam, status }: any) => {
  let params = '?';
  if (status) params += `status=${status}`;

  if (pageParam) params += `&skip=${pageParam}`;
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
