import { components } from 'react-select';
import { SingleOption } from './singleOption';

export const Value = (props: any) => {
  const { content } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <SingleOption content={content} />
    </components.SingleValue>
  );
};
