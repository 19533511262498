//@ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { SESSION_ITEM_DATA_INTER } from '../../data/session/constant';
import axios from 'axios';
import { BASE_URL } from '../../lib/http/constant';
import { SessionItemRoomLiveVideoContainer } from '../session-item-room-live-video';

export const Component: React.FC<{
  data?: SESSION_ITEM_DATA_INTER;
  isLoading?: boolean;
  sessionStart: boolean;
  isSuccess?: boolean;
}> = ({ isLoading, isSuccess, data, sessionStart }) => {
  const videoRef = useRef(null);
  const [src, setSrc] = useState('');

  useMemo(() => {
    if (data?.id) {
      const uniqueParam = new Date().getTime();
      let videoSrc = '';

      if (sessionStart) {
        if (data.auto) {
          videoSrc = `${BASE_URL}/session/videoStream/${data?.id}?t=${uniqueParam}`;
        } else {
          videoSrc = data.webinar.youtubeLink;
        }
      } else {
        videoSrc = `${BASE_URL}/session/previewStream/${data?.id}`;
      }

      setSrc(`${videoSrc}?_=${uniqueParam}`);
    }
    if (src) {
      const player = new Plyr(videoRef.current, {
        controls: ['mute', 'volume', 'fullscreen'],
        clickToPlay: false,
        clickToPause: false,
        autoplay: true,
        muted: true,
        loop: { active: true },
      });

      // Ensure the player is muted on load
      player.on('ready', () => {
        player.muted = true;
      });

      return () => {
        player.destroy();
      };
    }
  }, [data?.id, sessionStart]);

  // useEffect(() => {}, [data?.id, sessionStart]);

  return (
    <div style={{ height: 'calc(100%)', width: '100%' }}>
      {isSuccess && !!src && (
        <>
          {data?.sessionStarted && !data.auto ? (
            <SessionItemRoomLiveVideoContainer src={src} />
          ) : (
            <video
              ref={videoRef}
              className="plyr-react plyr"
              style={{
                margin: 'auto',
              }}
              muted={true}
              autoPlay={true}
              loop
              src={src}
              type="video/mp4"
            ></video>
          )}
        </>
      )}

      {isLoading && !isSuccess && !src && (
        <SkeletonField
          title={false}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </div>
  );
};
const SkeletonField = styled(SkeletonFieldElem)`
  .field {
    height: 100%;
    width: 100%;
    min-height: 390px;
  }
`;
