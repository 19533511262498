import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { RouteComponentProps } from 'react-router';
import { SessionDataContainer } from '../../epic/session-data';
import { SessionHeaderContainer } from '../../epic/session-header';
import { LayoutSectionElem } from '../../common/layout-section';
import { SessionItemStartContainer } from '../../epic/session-item-start';
import { MenuAppContainer } from '../../epic/menu-app';

interface SessionItemPageProps
  extends RouteComponentProps<{
    sessionId: string;
  }> {}

export const Page: React.FC<SessionItemPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;

  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />

        <LayoutElem size="default">
          <SessionDataContainer sessionId={sessionId} />
          <SessionHeaderContainer size="default" sessionId={sessionId} />
          <LayoutSectionElem spacing={8}>
            <SessionItemStartContainer sessionId={sessionId} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
