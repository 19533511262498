import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';

import { WebinarItemImportContainer } from '../../epic/webinar-item-import';
import { RouteComponentProps, useLocation } from 'react-router';
import { getQuery } from '../../lib/util/getQuery';
import queryString from 'query-string';

interface WebinarImportPageProps
  extends RouteComponentProps<{
    webinarId: string;
  }> {}

export const Page: React.FC<WebinarImportPageProps> = ({ match }) => {
  const webinarId = match.params.webinarId;

  const location = useLocation();
  const query = queryString.parse(location.search);
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />

        <LayoutElem>
          <HeaderContainer path title="WEBINAR.IMPORT.HEADER" />

          <LayoutSectionElem spacing={8}>
            <WebinarItemImportContainer id={webinarId} query={query} />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
