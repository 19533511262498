import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { FieldTextElem } from '../../../common/field-text';

export const TimecodeForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
}> = ({ formik, isFieldError, getFieldError, getFieldValue }) => {
  return (
    <GridElem spacing={5}>
      <DoubleContainerElem>
        <FieldTextElem
          name={FORM_VALUE_ENUM.MESSAGE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.NAME"
          value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
          error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
        />
      </DoubleContainerElem>
    </GridElem>
  );
};
