import React from 'react';
import { EVENT_ITEM_DATA_INTER } from '../constant';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { Spacing } from '../../../theme';

export const QuestionMobile: React.FC<{
  data: EVENT_ITEM_DATA_INTER;
}> = ({ data }) => {
  const { person, message } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem type="medium" color="textFourth" size="main" oneLine>
          {message}
        </TextElem>
      </EllipsisContainerElem>
      <TextElem
        type="medium"
        color="textPrimary"
        oneLine
        style={{ paddingLeft: `${Spacing(5)}` }}
      >
        {person.name}
      </TextElem>
    </>
  );
};
