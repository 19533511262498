import React from 'react';

import { FormikValues } from 'formik';
import styled from 'styled-components';

import { FORM_VALUE_ENUM } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { FieldPasswordElem } from '../../common/field-password';
import { FormElem } from '../../common/form';
import { LoaderElem } from '../../common/loader';
import { LinkElem } from '../../common/link';
import { AUTH_RECOVERY_PAGE_PATH } from '../../page/auth-recovery';
import { COLOR_ENUM } from '../../theme/color';
import logoIcon from '../../asset/svg/logo.svg';
import logoLightIcon from '../../asset/svg/logo-light.svg';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <Form onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridElem spacing={12}>
        <Wellcome spacing={4}>
          <Logo
            src={theme.type === THEME_ENUM.LIGHT ? logoLightIcon : logoIcon}
          />
          <Text>
            <TextElem>
              {i18n.t('AUTH.LOGIN.WELLCOME.1')}
              {i18n.t('AUTH.LOGIN.WELLCOME.SPACE')}
              <b>{i18n.t('AUTH.LOGIN.WELLCOME.2')}</b>
              {i18n.t('AUTH.LOGIN.WELLCOME.SPACE')}
              {i18n.t('AUTH.LOGIN.WELLCOME.3')}
            </TextElem>
          </Text>
        </Wellcome>
        <GridElem spacing={4}>
          <TitleContainer spacing={3}>
            <TextElem size="heading" type="semi-bold" tid="AUTH.LOGIN.TITLE" />
          </TitleContainer>
          <GridElem spacing={4}>
            <FieldText
              name={FORM_VALUE_ENUM.EMAIL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="AUTH.LOGIN.EMAIL"
              value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
              error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
              type="email"
            />

            <FieldPassword
              name={FORM_VALUE_ENUM.PASSWORD}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="AUTH.LOGIN.PASSWORD"
              value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
              error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
            />
          </GridElem>
          <LinkElem
            tid="AUTH.LOGIN.LINKS.RECOVERY.LINK"
            prefixTid="AUTH.LOGIN.LINKS.RECOVERY.PREFIX"
            link={AUTH_RECOVERY_PAGE_PATH}
          />

          <ButtonElem
            disabled={isSubmitDisabled()}
            type="submit"
            tid="AUTH.LOGIN.BUTTON"
          />

          {isError && <AlertActionElem text={errorMessage} type="error" />}
        </GridElem>
      </GridElem>
    </Form>
  );
};

const FieldPassword = styled(FieldPasswordElem)`
  input {
    :focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
    }

    :hover {
      background: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const FieldText = styled(FieldTextElem)`
  input {
    :focus-within {
      background: ${({ theme }) => theme[COLOR_ENUM.INPUT_BACKGROUND]};
    }

    :hover {
      background: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const Wellcome = styled(GridElem)`
  justify-content: center;
  padding: 0 ${Spacing(2)};
`;

const Text = styled.div`
  color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  font-size: 16px;
  display: inline !important;
  width: 230px;
  text-align: center;
  margin: auto;
  span {
    line-height: 150%;
  }
`;

const Logo = styled.img`
  height: 38px;
`;

const TitleContainer = styled(GridElem)`
  justify-items: center;
`;

const Form = styled(FormElem)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
