import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { TimerElem } from '../../common/timer';
import { TIMER_CONFIG } from '../auth-recovery-confirm/constant';
import { GridElem } from '../../common/grid';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  onButtonAction: any;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  active: boolean;
  handleSetActive: any;
}> = ({
  onButtonAction,
  isLoading,
  isError,
  errorMessage,
  handleSetActive,
  active,
}) => {
  return (
    <Container spacing={2} onClick={onButtonAction}>
      {isLoading && <LoaderElem />}
      <Message>
        <TextElem color="textThird" tid="AUTH.RECOVERY_CONFIRM.RESEND_TEXT" />
        <TextElem
          color="textThird"
          type="semi-bold"
          tid="AUTH.RECOVERY_CONFIRM.RESEND_LINK"
        />
      </Message>
      <TimerElem
        setVisible={handleSetActive(true)}
        visible={active}
        minutes={TIMER_CONFIG.minutes}
        seconds={TIMER_CONFIG.seconds}
      />
      {isError && <AlertActionElem text={errorMessage} />}
    </Container>
  );
};

const Message = styled.div`
  text-align: center;
  & > * {
    display: inline;
  }
`;

const Container = styled(GridElem)`
  cursor: pointer;
`;
