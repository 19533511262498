import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkout-form';

const Checkout: React.FC<{ reservationId: string }> = ({ reservationId }) => {
  const stripePromise = loadStripe(
    'pk_test_51NxYJqFwp1xPJMQby5suG9GhoP0DfMFEsmFWa0w7omgX8aGh7DwiVsdeStSagIKcodEU6TbfYEJIyu2qYc8HzcMP00ugaK2yrC',
  );

  const options = {
    mode: 'payment' as const,
    amount: 50000,
    currency: 'uah',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm reservationId={reservationId} />
    </Elements>
  );
};

export default Checkout;
