import React from 'react';

import { MODULE_NAME } from './constant';

import { useQuery } from 'react-query';
import { getViewer } from './action';
import { HttpRequest } from '../../lib/http';

export const Container: React.FC<{}> = ({}) => {
  const preFetch = useQuery([MODULE_NAME], () => getViewer(), {
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!HttpRequest.defaults.headers.common['session_token'],
  });

  preFetch.data;
  return <></>;
};
