import React from 'react';
import { action as deleteMember } from './action';
import { useMutation, useQueryClient } from 'react-query';
import { SESSION_LIST_MODULE_NAME } from '../session-list';
import { LoaderElem } from '../../common/loader';
import { ModalConfirmElem } from '../../common/modal-confirm';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';

export const Container: React.FC<{
  sessionId: string;
  open: boolean;
  onClose: Function;
}> = ({ sessionId, onClose, open }) => {
  const queryClient = useQueryClient();

  const action = useMutation(() => deleteMember(sessionId), {
    onSuccess: () => {
      return queryClient.invalidateQueries(SESSION_LIST_MODULE_NAME);
    },
  });

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const onClick = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    action.mutate();
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <div style={{ display: 'none' }}>
      {isLoading() && <LoaderElem />}

      <ModalConfirmElem
        action={onClick}
        open={open}
        onClose={onClose}
        type="delete"
      ></ModalConfirmElem>
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </div>
  );
};
