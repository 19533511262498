import React from 'react';
import styled from 'styled-components';
import { IonSkeletonText } from '@ionic/react';

import { GridElem } from '../../../common/grid';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { ReactComponent as CopyIcon } from '../../../asset/svg/common/copy.svg';

import { CopyElem } from '../../../common/copy';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';
import { CardElem } from '../../../common/card';
import { Spacing } from '../../../theme';
import { Card } from './file-item-list.elem ';

const Elem: React.FC = () => {
  return (
    <Card hover={false}>
      <Preview></Preview>

      <InfoContainer>
        <GridElem
          spacing={3}
          style={{
            maxWidth: '100%',
          }}
        >
          <IonSkeletonText style={{ width: '80px', height: '14px' }} animated />

          <IonSkeletonText style={{ width: '90%', height: '16px' }} animated />
          <IonSkeletonText style={{ width: '60%', height: '16px' }} animated />
          <IonSkeletonText style={{ width: '90%', height: '14px' }} animated />
        </GridElem>
        <CopyElem value={''}>{/* <CopyIcon /> */}</CopyElem>
      </InfoContainer>
    </Card>
  );
};

const Preview = styled.div`
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;

  height: 100%;
  width: 100%;
  padding: 18px 23px;
`;

const InfoContainer = styled(GridElem)`
  grid-template-columns: auto 18px;
  align-items: center;
`;

export { Elem as FileSkeletonElem };
