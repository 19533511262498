import React from 'react';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { GridElem } from '../../common/grid';
import { SkeletonFieldElem } from '../../common/skeleton-field';
import { TextElem } from '../../common/text';

export const Skeleton: React.FC<{}> = ({}) => {
  return (
    <ContentContainerElem>
      <GridElem spacing={5}>
        <TextElem tid="WEBINAR.UPDATE.TITLE" type="semi-bold" size="main" />
        <GridElem spacing={4}>
          <SkeletonFieldElem />
          <SkeletonFieldElem />
        </GridElem>
      </GridElem>
    </ContentContainerElem>
  );
};
