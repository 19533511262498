import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { FORM_VALUE_ENUM } from '../project-item-create/constant';
import { FieldTextAreaElem } from '../../common/field-text-area';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <TitleContainer>
          <TextElem tid="PROJECT.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer>
        <DoubleContainerElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="PROJECT.FORM.NAME"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />

          <FieldTextElem
            name={FORM_VALUE_ENUM.SMS_ALPHA_NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="PROJECT.FORM.SMS_ALPHA_NAME"
            value={getFieldValue(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
            error={isFieldError(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
          />
        </DoubleContainerElem>
        <FieldTextAreaElem
          name={FORM_VALUE_ENUM.SMS_TEXT}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="PROJECT.FORM.SMS_ALPHA_NAME"
          value={getFieldValue(FORM_VALUE_ENUM.SMS_TEXT)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.SMS_TEXT)}
          error={isFieldError(FORM_VALUE_ENUM.SMS_TEXT)}
        />
        <DoubleContainerElem>
          <ButtonStyled
            type="submit"
            tid="PROJECT.UPDATE.BUTTON"
            fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};

const ButtonStyled = styled(ButtonElem)`
  margin-top: 26px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;
