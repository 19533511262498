import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import queryString from 'query-string';

import { AuthRecoveryPasswordContainer } from '../../epic/auth-recovery-password';
import { LayoutAuthElem } from '../../common/layout-auth';

export const Page: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const code = query.code;

  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <LayoutAuthElem>
          <AuthRecoveryPasswordContainer code={code} />
        </LayoutAuthElem>
      </LayoutAppElem>
    </PageElem>
  );
};
