import {
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
} from '../webinar-item-update-general/constant';

export const convert = (data: FORM_VALUE_INTER) => ({
  ...data,
  [FORM_VALUE_ENUM.BONUS]: data[FORM_VALUE_ENUM.BONUS]?.map((item: string) =>
    item.trim(),
  ),
});
