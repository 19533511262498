import React from 'react';
import { IonSkeletonText } from '@ionic/react';

import styled from 'styled-components';
import { CardElem } from '../../../common/card';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { VALUE_OPACITY_ENUM } from '../../../theme/value';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { MobileField } from './expenditure-item-list.elem';

export const Elem: React.FC = ({}) => {
  return (
    <>
      {[1, 2, 3, 4].map(() => (
        <DesktopCard>
          <SkeletonTitle animated={true} style={{ width: '95px' }} />

          <SkeletonTitle animated={true} style={{ width: '130px' }} />
          <SkeletonTitle animated={true} style={{ width: '70px' }} />
          <SkeletonTitle animated={true} style={{ width: '70px' }} />
          <SkeletonTitle animated={true} style={{ width: '135px' }} />
          <SkeletonTitle animated={true} style={{ width: '145px' }} />
          <SkeletonTitle animated={true} style={{ width: '140px' }} />
          <SkeletonTitle animated={true} style={{ width: '135px' }} />
          <SkeletonTitle animated={true} style={{ width: '130px' }} />
        </DesktopCard>
      ))}
      {[1, 2, 3, 4].map(() => (
        <MobileCard>
          <MobileField spacing={4}>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.DATE"
            />

            <SkeletonTitle animated={true} style={{ width: '75px' }} />
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.DEADLINE"
            />
            <SkeletonTitle animated={true} style={{ width: '75px' }} />
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.AMOUNT"
            />
            <SkeletonTitle animated={true} style={{ width: '50px' }} />
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.BILL"
            />
            <SkeletonTitle animated={true} style={{ width: '70px' }} />
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.DEPARTMENT"
            />
            <SkeletonTitle animated={true} style={{ width: '90px' }} />
          </MobileField>

          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.CATEGORY"
            />
            <SkeletonTitle animated={true} style={{ width: '120px' }} />
          </MobileField>

          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.RESPONSIBLE"
            />
            <SkeletonTitle animated={true} style={{ width: '145px' }} />
          </MobileField>
          <MobileField>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.STATUS"
            />
            <SkeletonTitle animated={true} style={{ width: '80px' }} />
          </MobileField>
          <MobileField style={{ gridColumn: '1/5' }}>
            <TextElem
              type="medium"
              color="textPrimary"
              tid="EXPENDITURE.HEAD.COMMENT"
            />
            <SkeletonTitle animated={true} style={{ width: '80px' }} />
          </MobileField>
        </MobileCard>
      ))}
    </>
  );
};

const DesktopCard = styled(CardElem)`
  display: grid;
  cursor: auto;
  align-items: center;
  grid-column: span 9;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;

  @media screen and (width<=1100px) {
    display: none;
  }

  .mobileContainer {
    display: none;

    @media screen and (width<1100px) {
      display: grid;
    }
  }
`;

const MobileCard = styled(CardElem)`
  cursor: auto;

  display: none;
  align-items: center;
  grid-template-columns: subgrid;
  gap: ${Spacing(5)};
  justify-content: space-between;
  grid-column: span 4;

  width: 100%;
  min-width: 100%;
  @media screen and (width<1100px) {
    display: grid;
  }

  @media screen and (width<700px) {
    gap: ${Spacing(4)};
    grid-column: span 2;
  }

  @media screen and (width<580px) {
    gap: ${Spacing(4)};
    grid-column: span 1;
  }
`;

const SkeletonTitle = styled(IonSkeletonText)`
  height: 18.75px;
  margin: 0;
  width: 150px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
`;

export { Elem as ExpenditureSkeletonElem };
