import React from 'react';

import styled from 'styled-components';

import { ToggleElem } from '../../common/toggle';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  active: boolean;
  handleClick: Function;
  isSystem: boolean;
}> = ({ handleClick, active, isSystem }) => {
  return (
    <Toggle
      checked={active}
      handleClick={handleClick}
      tid={
        active
          ? 'NAVIGATION.TAB_APP.THEME_LIGHT'
          : 'NAVIGATION.TAB_APP.THEME_DARK'
      }
    />
  );
};

const Toggle = styled(ToggleElem)`
  --background: ${({ theme }) => theme[COLOR_ENUM.TOGGLE_BACKGROUND]};

  --handle-background: ${({ theme }) => theme[COLOR_ENUM.TOGGLE_HANDLE]};
`;
