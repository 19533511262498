import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { AlertActionElem } from '../../common/alert-action';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';

import { DoubleContainerElem } from '../../common/double-container';
import { LoaderElem } from '../../common/loader';
import { FormElem } from '../../common/form';
import { ButtonElem } from '../../common/button';
import { Skeleton } from './skeleton';
import { ContentContainerElem } from '../../common/content-container';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isLoadingPreFetch?: boolean;
  isSuccessPreFetch?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  isLoadingPreFetch,
  isSuccessPreFetch,
  isSuccess,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isLoadingPreFetch && <Skeleton />}
      {isSuccessPreFetch && (
        <ContentContainerElem>
          <GridElem spacing={5}>
            <TextElem
              tid="WEBINAR.UPDATE.LINK.TITLE"
              type="semi-bold"
              size="main"
            />

            <FormElem onSubmit={formik.handleSubmit}>
              <GridElem spacing={4}>
                <DoubleContainerElem>
                  <FieldTextElem
                    name={FORM_VALUE_ENUM.NAME}
                    onChange={formik.handleChange}
                    // onBlur={formik.handleSubmit}
                    title="WEBINAR.UPDATE.LINK.FORM.NAME"
                    value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                    error={isFieldError(FORM_VALUE_ENUM.NAME)}
                  />

                  <FieldTextElem
                    name={FORM_VALUE_ENUM.LINK}
                    onChange={formik.handleChange}
                    // onBlur={formik.handleSubmit}
                    title="WEBINAR.UPDATE.LINK.FORM.LINK"
                    value={getFieldValue(FORM_VALUE_ENUM.LINK)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.LINK)}
                    error={isFieldError(FORM_VALUE_ENUM.LINK)}
                  />
                </DoubleContainerElem>

                <FieldTextElem
                  name={FORM_VALUE_ENUM.DESCRIPTION}
                  onChange={formik.handleChange}
                  // onBlur={formik.handleSubmit}
                  title="WEBINAR.UPDATE.LINK.FORM.DESCRIPTION"
                  value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
                  error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
                />
                <DoubleContainerElem>
                  <ButtonElem
                    type="submit"
                    tid="WEBINAR.UPDATE.LINK.BUTTON.CREATE"
                    disabled={isSubmitDisabled()}
                    color="success"
                  />
                </DoubleContainerElem>
              </GridElem>
              {isSuccess && <AlertActionElem type="success" tid="Success" />}

              {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            </FormElem>
          </GridElem>
        </ContentContainerElem>
      )}
    </>
  );
};
